@import '../../../../../../scss/mixins';

.modal-messenger{
  &__available{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 8px;
  }

  &__subtitle{
    color: rgba(136, 136, 136, 1);
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
  }

  .ant-picker-outlined:focus-within{
    box-shadow: none !important;
  }

  &__input_wrap{
    margin-top: 24px;

    &__input {
      margin-top: 8px;
      background: #F1F1F1;
      padding: 7px 12px;
      border-radius: 8px;
      width: 100%;
      border: none;
      outline: none;
      font-family: 'Inter-Regular', sans-serif;
      font-size: 14px;

      @include mobile{
        padding: 10px 12px;
      }
    }

    &__input-datePicker{
      margin-top: 8px;
      font-family: 'Inter-Regular', sans-serif;
      font-size: 14px;
      padding: 0;
      border: none;

      .ant-picker-cell-today{
        .ant-picker-cell-inner{
          &:before{
            border: none !important;
          }
        }
      }

      .ant-picker-panel-container{
        border-radius: 16px !important;
      }

      .ant-picker-footer{
        display: none!important;
      }

      .ant-picker-header{
        border-bottom: none !important;
      }

      .ant-picker-decade-btn{
        &:hover{
          color: rgb(136, 136, 136) !important;
        }
      }

      .ant-picker-cell-in-view{
        .ant-picker-month-btn, .ant-picker-year-btn, .ant-picker-cell-inner{
          color: rgb(34, 34, 34);
        }
      }

      .ant-picker-month-btn, .ant-picker-year-btn, .ant-picker-cell-inner{
        font-family: 'Inter-Medium', sans-serif;
        font-size: 14px;
        color: rgba(136, 136, 136, 1);
        font-weight: 500 !important;

        &:hover{
          color: rgb(136, 136, 136) !important;
        }
      }

      .ant-picker-cell-selected{

        .ant-picker-cell-inner{
          background: rgb(136, 136, 136) !important;
        }
      }



      .ant-picker-content{
        thead{
          th{
            font-family: 'Inter-Medium', sans-serif;
            font-size: 14px;
            color: rgba(136, 136, 136, 1);
          }
        }
      }

      &:focus{
        border: none !important;
        box-shadow: none;
      }

      .ant-picker-input{
        background: #F1F1F1;
        padding: 9px 12px;
        border-radius: 8px;

        input{

          &::placeholder{
            color: rgba(136, 136, 136, 1);
          }
        }
      }

      .ant-picker-clear{
        padding-right: 13px;
      }

      &:hover{
        border: none !important;
        background: transparent !important;
      }
    }
  }

  &__order-completed{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  &__rate{
    margin-top: 24px;

    &__select-stars{
      border: 1px solid rgba(241, 241, 241, 1);
      padding: 28px 0;
      display: flex;
      justify-content: center;

      &__root-stars{
        svg{
          font-size: 30px;
        }
      }
    }

    &__textArea{

      textarea{
        background: rgba(241, 241, 241, 1);
        padding: 8px 12px;
        border-radius: 8px;
        width: 100%;
        border: none;
        resize: none;
        margin-top: 24px;
        height: 80px;
        outline: none;

        font-family: 'Inter-Medium', sans-serif;
        font-size: 14px;

        &::placeholder{
          color: rgba(136, 136, 136, 1);
        }
      }
    }
  }
}
.newMessageToast{
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    object-fit: cover;
  }
}

.content{
  .chatName{
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
    color: #000;
  }

  .message{
    @extend .chatName;
    color: rgb(160, 160, 160);
  }
}
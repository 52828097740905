.auth {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 550px) {
    padding: 0 10px;
  }

  & input:user-invalid {
    outline-color: rgba(255, 0, 0, 0.5);
  }

  & input {
    font-family: "Inter-Regular", sans-serif;
  }

  &-main {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &__terms {
      position: absolute;
      bottom: 18px;
      left: 50%;
      translate: -50% 0;
      font-size: 12px;
      color: black;
      opacity: .5;
      transition: .2s;

      &:hover {
        opacity: 1;
      }
    }
  }

  // Login form
  &-login {
    display: flex;
    max-width: 360px;
    flex-direction: column;
    letter-spacing: -0.32px;
    justify-content: start;
    font: 16px/1.4 "Inter-Regular" sans-serif;
    margin: auto;

    &__logo {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      font-family: "Inter-Regular", sans-serif;

      & img {
        aspect-ratio: 1;
        width: 16px;
        margin-right: 5px;
      }
    }

    &__title {
      color: rgba(34, 34, 34, 1);
      letter-spacing: -4%;
      font-size: 40px;
      line-height: 40px;
      font-weight: 500;
      font-family: "Inter-Bold", sans-serif;
    }

    &__form {
      display: flex;
      margin-top: 40px;
      width: 100%;
      flex-direction: column;
      color: rgba(27, 31, 38, 0.72);
      font-weight: 400;
      justify-content: start;
    }

    &__input {
      flex: 1;
      border-radius: 10px;
      background-color: #f1f1f1;
      min-height: 44px;
      width: 100%;
      gap: 10px;
      white-space: nowrap;
      padding: 14px 15px;
      border: none;
      font-size: 16px;
      outline: none;

      &__error-msg {
        color: rgba(255, 59, 48, 1);
        font-size: 14px;
        margin-top: 10px;
        font-weight: 400;
        font-family: "Inter-Regular", sans-serif;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-background-clip: text;
        -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.03) inset;
      }

      &--password {
        flex: 1;
        flex-basis: 0;
        margin: auto 0;
        background: transparent;
      }
    }

    &__password-container {
      align-items: center;
      border-radius: 10px;
      background-color: #f1f1f1;
      display: flex;
      margin-top: 15px;
      min-height: 50px;
      width: 100%;
      gap: 10px;
      white-space: nowrap;
      justify-content: start;
      padding-right: 10px;
    }

    &__captcha-container {
      margin-top: 15px;
    }

    &__visibility-toggle {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
    }

    &__visibility-icon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 20px;
      align-self: stretch;
      margin: auto 0;
    }

    &__forgot-password {
      letter-spacing: -0.3px;
      margin-top: 15px;
      font: 500 14px "Inter-Regular", sans-serif;
      color: rgba(34, 34, 34, 1);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &__button-container {
      display: flex;
      margin-top: 40px;
      width: 100%;
      flex-direction: column;
      font-weight: 500;
      white-space: nowrap;
      justify-content: start;
    }

    &__button {
      border-radius: 8px;
      min-height: 40px;
      width: 100%;
      gap: 10px;
      padding: 0;
      cursor: pointer;
      border: none;
      font-weight: 500;
      font-family: "Inter-Regular", sans-serif;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }


      &--primary {
        background-color: rgba(0, 0, 0, 1);
        color: #fff;

        &:hover {
          background-color: rgba(0, 0, 0, 0.75);
        }
      }

      &--secondary {
        border: 1px solid #e5e5ea;
        background-color: rgba(0, 0, 0, 0.08);
        color: #222;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    &__divider {
      display: flex;
      margin: 15px 0;
      width: 100%;
      align-items: center;
      gap: 10px;
      color: rgba(60, 60, 67, 0.6);
      font-weight: 400;
      text-align: center;
      justify-content: center;
    }

    &__divider-line {
      background-color: #e5e5ea;
      align-self: stretch;
      width: 142px;
      height: 1px;
      flex: 1;
      flex-basis: 0;
      margin: auto 0;
      border: 1px solid #e5e5ea;
    }

    &__divider-text {
      align-self: stretch;
      margin: auto 0;
      font-family: "Inter-Regular", sans-serif;
    }

    &__label {
      &--visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }

  // Sign up form
  &-signup {
    display: flex;
    max-width: 320px;
    flex-direction: column;
    letter-spacing: -0.32px;
    justify-content: start;
    font-family: "Inter-Regular", sans-serif;
    margin: auto;

    &__logo {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 40px;
      display: flex;
      align-items: center;

      & img {
        aspect-ratio: 1;
        width: 16px;
        margin-right: 5px;
      }
    }

    &__title {
      & h1 {
        font-size: 40px;
        font-weight: 500;
        font-family: "Inter-Bold", sans-serif;
        letter-spacing: -4%;
        line-height: 40px;
      }

      & h4 {
        font-family: "Inter-Regular", sans-serif;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -2%;
        line-height: 20px;
        color: rgba(136, 136, 136, 1);
      }
    }

    &__form {
      display: flex;
      margin-top: 40px;
      width: 100%;
      flex-direction: column;
      color: rgba(27, 31, 38, 0.72);
      font-weight: 400;
      justify-content: start;
    }

    &__input {
      flex: 1;
      border-radius: 10px;
      background-color: #f1f1f1;
      min-height: 44px;
      width: 100%;
      gap: 10px;
      white-space: nowrap;
      padding: 14px 15px;
      border: none;
      font-size: 16px;
      outline: none;
      margin-bottom: 15px;

      &__error {
        @extend .auth-signup__input;
        border: 1px solid rgba(255, 0, 0, 0.5);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-background-clip: text;
        -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.03) inset;
      }
    }

    &__button {
      align-self: stretch;
      border-radius: 8px;
      min-height: 44px;
      width: 100%;
      gap: 10px;
      padding: 14px 15px;
      cursor: pointer;
      border: none;
      font-weight: 500;

      &-container {
        display: flex;
        margin-top: 40px;
        width: 100%;
        flex-direction: column;
        font-weight: 500;
        white-space: nowrap;
        justify-content: start;
      }

      &--primary {
        background-color: black;
        color: #fff;

        &:hover {
          opacity: 0.9;
        }
      }

      &--secondary {
        border: 1px solid #e5e5ea;
        background-color: rgba(0, 0, 0, 0.4);
        color: rgba(27, 31, 38, 0.72);

        &:hover {
          background-color: rgba(0, 0, 0, 0.025);
        }
      }
    }
  }

  // Reset password form
  &-reset {
    display: flex;
    max-width: 360px;
    flex-direction: column;
    letter-spacing: -0.32px;
    justify-content: start;
    font: 16px/1.4 "Inter-Regular" sans-serif;
    margin: auto;

    &__logo {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      font-family: "Inter-Regular", sans-serif;

      & img {
        aspect-ratio: 1;
        width: 16px;
        margin-right: 5px;
      }
    }

    &__title {
      & h1 {
        font-family: "Inter-Bold", sans-serif;
        font-size: 40px;
        font-weight: 500;
        letter-spacing: -4%;
        line-height: 40px;
      }

      & h4 {
        font-family: "Inter-Regular", sans-serif;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -2%;
        line-height: 20px;
        color: rgba(136, 136, 136, 1);
      }
    }

    &__form {
      display: flex;
      margin-top: 40px;
      width: 100%;
      flex-direction: column;
      color: rgba(27, 31, 38, 0.72);
      font-weight: 400;
      justify-content: start;
    }

    &__input {
      flex: 1;
      border-radius: 10px;
      background-color: #f1f1f1;
      min-height: 44px;
      width: 100%;
      gap: 10px;
      white-space: nowrap;
      padding: 14px 15px;
      border: none;
      font-size: 16px;
      outline: none;

      &__error-msg {
        color: rgba(255, 59, 48, 1);
        font-size: 14px;
        margin-top: 10px;
        font-weight: 400;
        font-family: "Inter-Regular", sans-serif;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-background-clip: text;
        -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.03) inset;
      }

    }

    &__button-container {
      display: flex;
      margin-top: 40px;
      width: 100%;
      flex-direction: column;
      font-weight: 500;
      white-space: nowrap;
      justify-content: start;
    }

  }


  &-form {
    background: #ffffff;
    box-shadow:1px 2px 12px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    max-width: 534px;
    width: 100%;
    padding: 44px 55px 20px 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 5%;

    @media (max-width: 1100px) and (max-height: 850px) {
      background: none;
      box-shadow: none;
    }

    @media (max-width: 1100px) {
      margin-top: 0;
      padding: 1% 10% 10% 10%;
      align-items: center;
    }

    &_disabled &-header,
    &_disabled &-body {
      opacity: .25;
      pointer-events: none;
      user-select: none;
      @media (max-width: 1100px) {
        opacity: 0;
      }
    }

    &-back-to-login {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 0 0 auto;
      width: 100%;
      margin-top: 5%;
      @media (max-width: 641px) {
        margin-top: 3%;
      }

      & svg {
        margin-right: 1.5%;
      }

    }

    &__later {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 30px auto 0 auto;
      color: var(--color-gray);
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: .2s;

      & svg path {
        fill: var(--color-gray);
        transition: .2s;
      }

      &:hover {
        color: var(--color-gray-hover);

        & svg path {
          fill: var(--color-gray-hover);
        }
      }
    }

    &-header {
      width: 100%;
      margin-bottom: 18px;
      @media (max-width: 1100px) {
        text-align: center;
        margin-bottom: 10%;
      }

      &__title {
        font-size: 24px;
        font-weight: 800;
      }

      &__description {
        font-weight: 500;
        font-size: 14px;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 100%;
      margin-bottom: 12px;
      @media (max-width: 1100px) {
        margin-bottom: 24px;
      }

      &-tip {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
          font-size: 14px;
          font-weight: 300;
          color: black;
        }

        &-timer {
          display: flex;
          gap: 15px;

          &__time {
            font-size: 14px;
            font-weight: 700;
            color: black;
          }

          & .link {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }


    &__forgot-password {
      font-size: 14px;
      display: flex;
      width: 100%;
      margin-bottom: 5%;
      @media(max-width: 1100px) {
        margin-bottom: 10%;
        justify-content: center;
      }
    }

    &-captcha {
      width: 100%;
      text-align: center;
      margin: 32px 0;
    }

    &-controls {
      width: 100%;
      margin-bottom: 2.5%;
      display: flex;
      align-items: center;
      gap: 15px;
      @media (max-width: 1100px) {
        flex-direction: column;
        & .g-button {
          width: 65%;
          margin-bottom: 5%;

          @media (max-width: 641px) {
            margin-bottom: 2.5%;
          }

        }
      }

      &_half {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1100px) {
          width: 100%;
        }
      }
    }

    &-oauth {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      @media (max-width: 1100px) {
        flex-direction: column;
      }

      &__title {
        flex-basis: 100%;
        font-size: 14px;
        font-weight: 500;
        color: black;
        text-align: center;
      }

      &-buttons {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        gap: 15px;

        @media (max-width: 1100px) {
          width: 65%;
        }

        &__button {
          flex-basis: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border: 1px solid var(--color-gray);
          border-radius: 8px;
          cursor: pointer;
          transition: .2s;

          &:hover {
            border-color: var(--color-gray-hover);
          }
        }
      }
    }

    &-confirmation {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-bottom: 24px;
      @media (max-width: 1100px) {
        width: 85%;
        text-align: center;
      }
    }

    &-authenticators {
      width: 100%;
      margin: 80px 0 45px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 64px;
      @media (max-width: 1100px) {
        gap: 20px;
      }

      &-element {
        flex-basis: 172px;
        aspect-ratio: 1 / 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 14px;
        padding: 24px;
        box-shadow: 2.5px 2.5px 15px 0 #00000026;
        border-radius: 8px;
        transition: .2s;
        cursor: pointer;

        &:hover,
        &_active {
          box-shadow: 2.5px 2.5px 15px 0 #00000050;
        }

        & img {
          width: 72px;
          height: 72px;
        }

        &__title {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          color: black;
          text-align: center;
        }
      }
    }

    &-modal {
      position: absolute;
      top: 42%;
      left: 50%;
      translate: -50% -50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      text-align: center;

      &__title {
        width: 236px;
        font-size: 24px;
        font-weight: 800;
        color: black;
      }

      &__description {
        width: 382px;
        font-size: 16px;
        font-weight: 500;
        color: black;
        @media (max-width: 1100px) {
          width: 339px;
        }
      }
    }
  }
}
@import "../../../../scss/mixins";

root {
  --dialog-opened: flex;
  --dialog-opened-pc: flex;
  --chat-list-z: 1;
}

.chats {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: start;

  &__title-mobile{
    display: none;
    padding: 20px 12px 24px;
    font-family: 'Inter-Bold', sans-serif;
    font-weight: 500;
    font-size: 27px;
    
    @include mobile{
        display: block;
    }
  }

  &__list {
    display: flex;
    max-width: 248px;
    min-width: 248px;
    height: 100%;
    flex-direction: column;
    font-family: Inter, sans-serif;
    line-height: 1.4;
    padding: 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    overflow-y: scroll;
    position: relative;
    z-index: 1;

    @include mobile {
      max-width: 100%;
      min-width: 100%;
    }
  }

  &__search {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.03);
    display: flex;
    min-height: 35px;
    width: 100%;

    align-items: center;
    gap: 5px;
    padding: 0 10px;
    margin-bottom: 20px;
    user-select: none;

    & input {
      background-color: rgba(248, 248, 248, 1);
      width: 100%;
      border-radius: 8px;
      border: none;
      outline: none;
      font-size: 14px;
      padding: 9.5px 12px;
      font-family: 'Inter-Medium', sans-serif;

      &:focus {
        font-size: 14px !important;
      }

      &::placeholder{
        color: rgba(160, 160, 160, 1);
      }
    }

    &-icon {
      width: 22px;
      aspect-ratio: 1 / 1;
      object-fit: contain;
    }
  }


  &__section {
    margin-bottom: 24px;

    &-header {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 6px 12px;
      width: fit-content;
    }

    &-title {
      font-size: 13px;
      color: #000;
      font-weight: 400;
      letter-spacing: -0.13px;
      user-select: none;
      font-family: 'Inter-Medium', sans-serif;
    }

    &-icon {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }


  &__item {
    display: flex;
    min-height: 60px;
    width: 100%;
    align-items: center;
    gap: 12px;
    padding: 12px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    border-radius: 12px;

    &-active{
      background: rgba(248, 248, 248, 1);
    }

    &__avatar-wrap{
      position: relative;
      width: 36px;
      height: 36px;
    }

    &__online{
      position: absolute;
      right: 0px;
      bottom: 0px;
      outline: solid 2px rgba(255, 255, 255, 1);
      background: rgba(52, 199, 89, 1);
      width: 7px;
      height: 7px;
      border-radius: 5px;

    }

  }

  &__avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: transparent;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-weight: 600;
    letter-spacing: -0.15px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
    color: rgba(34, 34, 34, 1);
  }

  &__verified-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  &__time {
    font-weight: 400;
    letter-spacing: -0.13px;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
    color: rgba(160, 160, 160, 1);
  }

  &__description {
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 70%;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
    color: rgba(160, 160, 160, 1);
  }

  &__unread-badge {
    border-radius: 999px;
    background-color: #222;
    min-width: 20px;
    height: 20px;
    font-weight: 400;
    letter-spacing: -0.13px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;

    span{
      font-family: 'Inter-Medium', sans-serif;
      font-size: 14px;
      color: rgba(255, 255, 255, 1);
      line-height: 12px;
    }
  }

  &__emptyMessegesScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    @include mobile{
      display: none;
    }

    span{
      font-family: 'Inter-Medium', sans-serif;
      font-size: 14px;
      color: rgba(34, 34, 34, 1);
      padding: 12px 16px;
      background: rgba(248, 248, 248, 1);
      border: 1px solid rgba(241, 241, 241, 1);
      border-radius: 16px;
    }
  }
}


input[type='text']:focus,
input[type='number']:focus,
textarea:focus {
  font-size: 16px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

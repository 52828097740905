.modal-order {
  &__footer-btn {
    width: 100%;
    background-color: rgba(34, 34, 34, 1);
    color: #fff;
    height: 40px;
    border-radius: 8px;
    padding: 0 12px;

    &:disabled {
      cursor: not-allowed;
      background-color: rgba(34, 34, 34, 0.7);
    }
  }

  &__input {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 7px;

    &__label {
      font-family: "Inter-Regular", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: -0.02em;
      color: rgba(136, 136, 136, 1);
    }

    &__field {
      height: 36px;
      width: 100%;
      padding: 0 12px 0 12px;
      border-radius: 8px;
      border: none;
      outline: none;
      background-color: rgba(241, 241, 241, 1);
      font-size: 14px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 45vh;

    @media (max-width: 641px) {
      height: 60vh;
    }

    &__header {
      display: flex;
      justify-content: start;

      & span {
        font-family: "Inter-Regular", sans-serif;
        font-size: 27px;
        font-weight: 700;
        line-height: 32.4px;
        letter-spacing: -0.02em;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin-top: 30px;
      gap: 30px;

      @media (max-width: 641px) {
        gap: 50px;
      }
    }

    &__amount {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-top: 30px;

      &__input-wrapper {
        display: flex;
        width: 100%;
        color: rgba(34, 34, 34, 1);
        font-size: 27px;
        font-weight: 700;
        font-family: "Inter-Regular", sans-serif;

        @media (max-width: 641px){
          padding-left: 50px;
        }
      }

      &__input {
        background-color: transparent;
        border: none;
        outline: none;
        color: rgba(34, 34, 34, 1);
        font-size: 27px;
        font-weight: 700;
        font-family: "Inter-Regular", sans-serif;
      }

      &__button-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;
        flex-wrap: wrap;
        margin-top: 5px;
      }

      &__button {
        background-color: rgba(241, 241, 241, 1);
        height: 36px;
        border-radius: 8px;
        padding: 0 12px;
        color: #222;
        font-size: 14px;
        font-family: "Inter-Regular", sans-serif;
      }
    }

  }
}

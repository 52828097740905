.modal-filter {
  font-family: "Inter-Regular", sans-serif;

  &__footer-btn {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 12px;
    background-color: rgba(34, 34, 34, 1);
    color: #fff;
    font-size: 14px;
    font-family: "Inter-Regular", sans-serif;
    font-weight: 600;

    &:hover {
      background-color: rgba(34, 34, 34, 0.85);
    }
  }

  &__input {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 7px;

    &__label {
      font-family: "Inter-Regular", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: -0.02em;
      color: rgba(136, 136, 136, 1);
    }

    &__field {
      height: 36px;
      width: 300px;
      padding: 0 12px 0 12px;
      border-radius: 8px;
      border: none;
      outline: none;
      background-color: rgba(241, 241, 241, 1);
      font-size: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 70vh;

    @media (max-width: 641px) {
      height: 80vh;
    }

    &__header {
      display: flex;
      justify-content: start;

      & span {
        font-family: "Inter-Regular", sans-serif;
        font-size: 27px;
        font-weight: 700;
        line-height: 32.4px;
        letter-spacing: -0.02em;
      }
    }

    &__options {
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 5px;
      margin-top: 20px;

      &__rating {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 10px;

        & h4 {
          font-family: "Inter-Regular", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
          color: rgba(136, 136, 136, 1);
        }

        &__container {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gap: 10px;
        }

        &__button {
          height: 32px;
          gap: 1px;
          font-family: "Inter-Regular", sans-serif;
          border-radius: 1000px;
          border: 1px solid rgba(241, 241, 241, 1);
          padding: 0 8px;
          display: flex;
          align-items: center;
          background-color: #fff;
          color: #222;

          & span {
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
            letter-spacing: -0.02em;
          }

          & img {
            aspect-ratio: 1;
            width: 16px;
          }
        }
      }

      &__tags {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }

      &__location {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 10px;

        & h4 {
          font-family: "Inter-Regular", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
          color: rgba(136, 136, 136, 1);
        }
      }

      &__languages {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
.tag-input {
  display: flex;
  flex-direction: column;
  font-family: "Inter-Regular", sans-serif;
  margin: 20px 0;

  &__label {
    margin-bottom: 8px;
    font-size: 14px;
    color: rgba(136, 136, 136, 1);
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 10px;
    background-color: #f6f6f6;
    align-items: center;
  }

  &__input {
    border: none;
    outline: none;
    padding: 8px;
    flex-grow: 1;
    font-size: 14px;
    border-radius: 8px;
    margin-right: 10px;
    background-color: transparent;
    width: 100%;

    &::placeholder {
      color: #b0b0b0;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    background-color: #e0e0e0;
    color: #333;
    padding: 5px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin: 5px;
    font-size: 14px;
  }

  &__remove {
    color: #666;
    margin-left: 8px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      color: #333;
    }
  }
}
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.finances {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (min-height: 350px) and (max-height: 667px) {
    height: calc(100% - 50px);
  }
    @media screen and (min-height: 668px) {
        height: calc(100vh - 50px);
    }

    @media screen and (max-width: 414px) {
        height: calc(100% - 50px);
    }
    @media screen and (max-width: 430px) {
      height: calc(100% - 50px);
    }
  &__content {
    display: flex;
    margin: 0 auto;

    @media (max-width: 641px) {
      width: 95%;
    }

    &__accounts {
      padding: 6rem 4rem;
      width: 100%;

      @media (max-width: 641px) {
        padding: 20px 10px 20px 10px;
      }

      @media screen and (min-width: 641px) and (max-width: 932px) {
        padding: 1rem 0;
      }

      &__title {
        font: 500 21px/1.2 "Inter-SemiBold", sans-serif !important;
        letter-spacing: -0.42px;
        color: #000;
        margin-bottom: 24px;
        @media (max-width: 641px) {
          font: 600 21px/1.2 "Inter-Medium", sans-serif;
          letter-spacing: -0.42px;
          color: #000;
          margin-bottom: 14px;
        }
      }

      &__balance-section {
        display: flex;
        gap: 20px;
        margin-bottom: 48px;

        @media (max-width: 991px) {
          flex-direction: column;
        }
      }

      &__balance-card {
        flex: 1;
        border-radius: 16px;
        border: 1px solid #f1f1f1;
        background-color: #fff;
        padding: 24px 22px;
        max-width: 312px;
        max-height: 256px;
        @media (max-width: 641px) {
          width: 80vw;
          margin: 0 auto;
        }

        &:last-child &__title {
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
          color: #A0A0A0;
        }

        &:last-child &__amount {
          color: #CFCFCF;
        }

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          @media (max-width: 641px) {
            margin-bottom: 8px;
          }
        }

        &__title {
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
          letter-spacing: -0.28px;
          color: #222;
        }

        &__info-button {
          background: none;
          border: none;
          cursor: not-allowed;
          padding: 0;
        }

        &__info-icon {
          width: 16px;
          height: 16px;
        }

        &__amount {
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
          letter-spacing: -0.28px;
          color: #222222;
          margin-bottom: 24px;
          @media (max-width: 641px) {
            margin-bottom: 0;
          }
        }

        &__percentage-change {
          display: flex;
          align-items: center;
          gap: 4px;
          margin-bottom: 40px;
          padding-top: 32px;
          @media (max-width: 641px) {
            padding-top: 10px;
          }
          &-ru {
            margin-bottom: 20px;
          }
        }

        &__trend-icon {
          width: 16px;
          height: 16px;
        }

        &__change-value {
          color: #34c759;
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
        }

        &__change-period {
          color: rgba(136, 136, 136, 1);
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
          text-wrap: nowrap;
        }

        &__action-buttons {
          display: flex;
          gap: 12px;
        }

        &__action-button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          background-color: #f1f1f1;
          border: none;
          border-radius: 8px;
          padding: 8px 12px;
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
          color: #222;
          cursor: pointer;

          &:hover {
            background-color: rgba(0, 0, 0, 0.15);
            transition: background-color .2s ease-in-out;
          }
          & > span {
            font: 500 14px/1.4 "Inter-Medium", sans-serif;
          }
        }

        &__button-icon {
          width: 16px;
          height: 16px;
        }

        &__unlock-info {
          color: #A0A0A0;
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
          padding-top: 30px;
          @media (max-width: 641px) {
            padding-top: 8px;
          }
        }

        @media (max-width: 991px) {
          padding: 18px 20px;

          &__percentage-change {
            margin-bottom: 40px;
          }
        }
        @media (max-width: 641px) {
          &__percentage-change {
            margin-bottom: 16px;
          }
        }
      }
    }

    &__history {
      margin-top: 48px;
      height: auto;
      width: 65vw;
      padding-bottom: 5%;
      @media (max-width: 641px) {
        padding-bottom: 70px;
      }
      // @media screen and (min-height: 434px) and (max-height: 667px) {
      //   height: 100vh;
      // }
      &__title {
        font: 500 21px/1.2 "Inter-SemiBold", sans-serif !important;
        letter-spacing: -0.42px;
        color: #000;
        margin-bottom: 26px;
      }

      &__filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 26px;

        &__chip-group {
          display: flex;
          gap: 8px;
        }

        &__chip {
          border: 1px solid #f1f1f1;
          border-radius: 1000px;
          padding: 6px 16px;
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
          color: #414141;
          background: none;
          cursor: pointer;

          &--active {
            background-color: #222;
            color: #fff;
            border-color: #222;
          }
        }
        &__chip:nth-child(2) {
          padding: 6px 7px;
        }
        &__chip:nth-child(3) {
          padding: 6px 7px;
        }
        &__chip:nth-child(4) {
          padding: 6px 7px;
        }
        &__chip:nth-child(5) {
          padding: 6px 7px;
        }

        &__period-selector {
          display: flex;
          align-items: center;
          gap: 4px;
          background: none;
          border: none;
          font: 500 14px/1.4 "Inter-Medium", sans-serif;
          color: #222;
          cursor: pointer;
        }

        &__selector-icon {
          width: 16px;
          height: 16px;
        }

        @media (max-width: 991px) {
          flex-wrap: wrap;
          gap: 10px;
          width: 100vw;

          &__chip-group {
            overflow-x: auto;
            scroll-behavior: smooth;
            scroll-snap-type: none;
            scrollbar-width: none;
          }
        }
      }

      &__table {
        @media (max-width: 641px) {
          display: none;
        }
      }

      &__type-wrapper {
        display: flex;
        width: 100%;
        gap: 16px;
        align-items: center;

        & div {
          display: flex;
          justify-content: center;
          align-items: center;
          aspect-ratio: 1;
          width: 36px;
          background-color: rgba(88, 86, 214, 0.16);
          border-radius: 10px;

          & img {
            aspect-ratio: 1;
            width: 16px;
          }
        }

        & span {
          color: rgba(34, 34, 34, 1);
          font-family: "Inter-Regular", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
        }
      }

      &__status-chip {
        &--completed, &--cancelled {
          display: inline-block;
          padding: 4px 8px;
          border-radius: 1000px;
          font-size: 12px;
          line-height: 1;
          font-family: "Inter-Medium", sans-serif;
        }

        &--completed {
          background-color: rgba(52, 199, 89, 0.12);
          color: #34c759;
        }

        &--cancelled {
          background-color: rgba(255, 59, 48, 0.12);
          color: #ff3b30;
        }
      }

      &__mobile {

      }
    }
  }

  &__balance {
    &-info {
      display: flex;
      width: 100%;
      flex-direction: column;
      font-family: Inter, sans-serif;
      justify-content: start;
    }

    &-section {
      display: flex;
      width: 100%;
      flex-direction: column;
      color: rgba(27, 31, 38, 0.72);
      justify-content: start;
    }

    &-label {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: -0.3px;
    }

    &-amount {
      font-size: 48px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: -2.4px;
      margin-top: 5px;
    }
  }

  &__locked {
    &-section {
      display: flex;
      margin-top: 20px;
      width: 100%;
      flex-direction: column;
      color: rgba(128, 128, 128, 0.55);
      justify-content: start;
    }

    &-amount {
      font-size: 17px;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: -0.34px;
      margin-top: 5px;
    }
  }

  &__action {
    &-buttons {
      display: flex;
      margin-top: 50px;
      min-height: 50px;
      width: 320px;
      max-width: 100%;
      gap: 10px;
      color: rgba(27, 31, 38, 0.72);
      white-space: nowrap;
      letter-spacing: -0.3px;
      justify-content: start;
      font: 500 15px/1.4 Inter, sans-serif;
    }

    &-button {
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
      height: 100%;
      flex: 1;
      flex-basis: 0;
      padding: 20px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    &-icon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 20px;
      align-self: stretch;
      margin: auto 0;
    }

    &-text {
      font-family: "Inter-Regular", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: -0.02em;
      align-self: stretch;
      margin: auto 0;
    }
  }

  &__operations {
    &-section {
      display: none;
      width: 100%;
      flex-direction: column;
      justify-content: start;

      @media (max-width: 641px) {
        display: flex;
      }
    }

    &-list {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: start;
      height: auto;
    }
  }

  &__operation {
    &-item {
      border-radius: 10px;
      display: flex;
      min-height: 60px;
      width: 100%;
      align-items: center;
      justify-content: start;
      padding: 0 10px;
      margin-top: 10px;
      position: relative;
      &:first-child {
        margin-top: 0;
      }

      @media (max-width: 641px) {
        padding: 0;
        &::after {
          content: ""; 
          position: absolute; 
          left: 0; 
          bottom: 0; 
          width: 90vw; 
          height: 1px; 
          background-color: #F1F1F1; 
          margin-top: 10px;
        }
      }
    }

    &-icon-wrapper {
      border-radius: 8px;
      background-color: rgba(88, 86, 214, 0.16);
      display: flex;
      width: 36px;
      height: 36px;
      align-items: center;
      justify-content: center;
      padding: 0 12px;

      & img {
        aspect-ratio: 1;
        width: 16px;
      }
    }

    &-details {
      align-self: stretch;
      display: flex;
      min-width: 240px;
      flex-direction: column;
      justify-content: start;
      flex: 1;
      flex-basis: 0%;
      margin: auto 0;
      font: 15px/1.4 Inter, sans-serif;
      @media screen and (max-width: 641px) {
        min-width: 225px
      }
    }

    &-title {
      font-family: "Inter-Medium", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }

    &-type {
      color: rgba(128, 128, 128, 0.55);
      font-weight: 400;
      letter-spacing: -0.3px;
      font-family: 'Inter-Regular', sans-serif;
    }

    &-status {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      margin-left: auto;

      & span {
        font-family: "Inter-Medium", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        letter-spacing: -0.02em;
        color: rgba(136, 136, 136, 1);
      }
    }

    &-amount {
      letter-spacing: -0.3px;
      align-self: stretch;
      margin: auto 0;
      font: 500 15px/1.4 Inter, sans-serif;

      &--positive {
        color: #34c759;
        @media screen and (max-width: 641px) {
          text-align: right;
          font-family: "Inter-Medium", sans-serif;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
        }
      }

      &--negative {
        color: rgba(27, 31, 38, 0.72);
        @media screen and (max-width: 641px) {
          text-align: right;
          font-family: "Inter-Medium", sans-serif;
          font-weight: 500;
          color: rgba(27, 31, 38, 0.72);
          line-height: 19.6px;
          letter-spacing: -0.02em;
        }
      }
    }
  }
  // End of main page
  // Deposit page
  &__deposit {
    border-radius: 0;
    display: flex;
    max-width: 320px;
    flex-direction: column;
    font-family: Inter, sans-serif;
    margin: 10% auto auto auto;

    &-header {
      align-self: start;
      display: flex;
      gap: 20px;
      font-size: 24px;
      color: rgba(27, 31, 38, 0.72);
      font-weight: 700;
      white-space: nowrap;
      letter-spacing: -0.72px;
      line-height: 1.2;
      align-items: center;

      & button {
        display: flex;
        align-items: center;
      }

      &-icon {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 20px;
        border-radius: 833px;
        margin: auto 0;
      }

      & h1 {
        font-size: 24px;
      }
    }

    &-content {
      display: flex;
      margin-top: 20px;
      width: 100%;
      padding-left: 40px;
      flex-direction: column;
      align-items: start;
      line-height: 1.4;
    }

    &-network {
      display: flex;
      width: 100%;
      flex-direction: column;
      white-space: nowrap;
      justify-content: start;
      gap: 10px;

      &-label {
        color: #414141;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.13px;
        font-family: "Inter-Regular", sans-serif;
      }
    }

    &-address {
      border-radius: 10px;
      display: flex;
      min-height: 60px;
      margin-top: 20px;
      width: 100%;
      align-items: center;
      gap: 20px;
      font-size: 15px;
      justify-content: start;

      &-info {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: start;
        flex: 1;
        flex-basis: 20px;
        margin: auto 0;
        overflow: hidden;
        gap: 10px;
      }

      &-label {
        font-family: "Inter-Regular", sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #414141;
        font-weight: 400;
        letter-spacing: -0.3px;
      }

      &-wrapper {
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
      }

      &-value {
        display: flex;
        background: linear-gradient(
            90deg, 
            #F1F1F1 0%, 
            #F1F1F1 80%, 
            rgba(200, 200, 200, 1) 100%
        );
        width: 100%;
        padding: 10px 12px;
        border-radius: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        
        mask-image: linear-gradient(
            to right, 
            rgba(0, 0, 0, 1) 60%, 
            rgba(0, 0, 0, 0.6) 80%, 
            rgba(0, 0, 0, 0) 100%
        );
    
        & span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            font-family: "Inter-Regular", sans-serif;
            letter-spacing: -0.15px;
            color: #222;
        }
    }
    

      &-copy {
        &-button {
          border-radius: 8px;
          background-color: rgba(34, 34, 34, 1);
          display: flex;
          height: 36px;
          align-items: end;
          gap: 5px;
          color: #fff;
          font-weight: 500;
          letter-spacing: -0.3px;
          justify-content: center;
          padding: 10px;

          &:hover {
            background-color: rgba(34, 34, 34, 0.85);
            transition: background-color .2s ease-in-out;
          }
          & > img {
            width: 16px;
          }
        }

        &-text {
          align-self: stretch;
          margin: auto 0;
        }

        &-icon {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 20px;
          align-self: stretch;
          margin: auto 0;
        }
      }
    }

    &-warning {
      display: flex;
      margin-top: 40px;
      align-items: start;
      gap: 5px;
      font-size: 13px;
      color: rgba(27, 31, 38, 0.72);
      font-weight: 400;
      letter-spacing: -0.13px;
      line-height: 18px;
      justify-content: start;

      &-icon {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 20px;
      }

      &-text {
        flex: 1;
        flex-basis: 0;
        max-width: 288px;
        font-size: 14px;
        color: #888888;
        font-family: "Inter-Medium", sans-serif;
        font-weight: 500;
      }
    }
  }
  //  End of deposit page
  //  Withdraw page
  &__withdraw {
    max-width: 320px;
    border-radius: 0;
    display: flex;
    gap: 20px;
    font-family: Inter, sans-serif;
    margin: 10% auto auto auto;

    &__go-back {
      &-btn {
        height: fit-content;
      }

      &-icon {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 20px;
        border-radius: 833px;
        align-self: start;
        margin-top: 5px;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: start;
      flex-grow: 1;
      flex-basis: 0;
      width: fit-content;
    }

    &__header {
      display: flex;
      width: 100%;
      flex-direction: column;
      color: rgba(27, 31, 38, 0.72);
      justify-content: start;
    }

    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.72px;
    }

    &__balance {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: -0.13px;
      margin-top: 5px;
    }

    &__amount-input {
      flex: 1;
      flex-basis: 0;
      margin: auto 0;
      font-size: 16px;
      border: none;
      outline: none;

      &::placeholder {
        opacity: 0.55;
      }

      &-wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: start;
        margin-top: 40px;
      }
    }

    &__details-section {
      display: flex;
      margin-top: 40px;
      width: 100%;
      flex-direction: column;
      line-height: 1.4;
      justify-content: start;
    }

    &__network-selector {
      display: flex;
      width: 100%;
      flex-direction: column;
      white-space: nowrap;
      justify-content: start;
    }

    &__selector-label {
      color: rgba(128, 128, 128, 0.55);
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.13px;
    }

    &__wallet-address {
      flex: 1;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.03);
      margin-top: 5px;
      min-height: 40px;
      width: 100%;
      gap: 10px;
      font-size: 16px;
      color: rgba(27, 31, 38, 0.72);
      white-space: nowrap;
      letter-spacing: -0.3px;
      padding: 10px;
      border: none;
      outline: none;

      &-section {
        display: flex;
        margin-top: 20px;
        width: 100%;
        flex-direction: column;
        font-weight: 400;
        justify-content: start;
      }

      &-label {
        color:rgba(128, 128, 128, 0.55);
        font-size: 13px;
        letter-spacing: -0.13px;
      }
    }

    &__security {
      &-note {
        display: flex;
        margin-top: 20px;
        width: 100%;
        align-items: start;
        gap: 5px;
        font-size: 13px;
        color: rgba(27, 31, 38, 0.72);
        font-weight: 400;
        letter-spacing: -0.13px;
        line-height: 18px;
        justify-content: start;
      }

      &-icon {
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 20px;
      }

      &-text {
        flex: 1;
        flex-basis: 0;
      }
    }

    &__confirm-button {
      border-radius: 10px;
      background-color: rgba(27, 31, 38, 0.72);
      margin-top: 40px;
      min-height: 40px;
      width: 100%;
      gap: 10px;
      font-size: 15px;
      color: #fff;
      font-weight: 500;
      white-space: nowrap;
      letter-spacing: -0.3px;
      line-height: 1.4;
      padding: 10px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: rgba(27, 31, 38, 0.6);
      }
    }
  }
  //  End of withdraw page
}

// Ant selected items style
.ant-select-item-option-selected {
  background-color: #F1F1F1 !important;
}
.ant-select-item-option-content {
  font-family: "Inter-Medium", sans-serif;
  font-weight: 500;
}
.ant-table-row-level-0 {
  & > td:nth-child(5) {
    text-align: right;
  }
}
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.select-dropdown {
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    color: black;
    font-family: "Inter-Medium", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.02em;
  }
}
.ant-select-selector {
  border: none !important;
  border-radius: 8px !important;
  padding: 18px 12px !important;
}
.finances__operations-date {
  color: #000000;
  font-family: "Inter-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: -0.02em;
  padding: 4px 0;
}
.finances__operations-date-group:nth-child(4) {
  .finances__operation-item > div:nth-child(2) {
    padding: 0 8px;
  }
}
.ant-table-cell, .ant-table-cell > span {
  font-family: "Inter-Regular", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: -0.02em;
}
.ant-table-cell[scope="col"] > div {
  & > span {
    font-family: "Inter-Medium", sans-serif;
    font-weight: 500;
  }
}
.ant-select-arrow {
  padding-top: 6px;
}
.ant-drawer-content {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.ant-drawer-header {
  padding: 8px 10px !important;
  border-bottom: none !important;
  &-title {
    justify-content: flex-end;
  }
}
.mobile-drawer-withdraw {
  overflow-y: auto !important;
  padding: 6px 24px !important;
}
@media (max-height: 375px) {
  // DRAWER DEPOSIT
  .ant-drawer-content-wrapper {
    height: 370px !important;
  }
  .mobile-drawer-withdraw {
    padding: 6px 16px !important;
  }
  .mobile-modal-deposit__content__wrapper {
    width: 79% !important;
  }
  #react-qrcode-logo  {
    height: 250px !important;
    width: 250px !important;
  }
  .ant-drawer-header-close-only {
    padding: 1px 10px !important;
    position: absolute;
    right: 0;
    top: 8px;
  }
  // DRAWER WITHDRAW
  .ant-drawer-footer {
    padding: 0 171px 3px 156px !important
  }
}

@media screen and (min-width: 390px) and (max-width: 430px) {
  .finances__operation-item {
    width: 90vw !important;
    &  > div:nth-child(2) {
      padding: 0 !important;
    }
  }
}

.ant-table-tbody > .ant-table-row-level-0 > td:nth-child(5) {
  color: rgba(88, 88, 88, 1);
  font-family: "Inter-Medium", sans-serif;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.02em;
}

.ant-select-selection-item {
  font-family: "Inter-Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
  line-height: 19.6px;
  letter-spacing: -0.02em;
}
.ant-empty {
  display: none;
}

.ant-table-wrapper .ant-table-tbody >tr >td  {
  border-bottom: 0px;
}
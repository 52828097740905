@import "../../../../scss/mixins";

.messenger {
  height: 100%;
  width: 100%;
  font-family: "Inter-Regular", monospace;
  position: relative;
  display: flex;
  background: #fff;

  @include mobile {
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0);
    bottom: 0;
    left: 0;
  }

  &-wrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    flex: 1;
  }

  // Header
  &__header {
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &__user-info {
      position: relative;
      display: flex;
      width: 100%;
      align-items: center;
      gap: 16px;
      justify-content: flex-start;
      flex: 1;
      height: 100%;
      padding: 16px 32px;

      @include mobile {
        padding: 6px 30px;
        background-color: rgba(241, 241, 241, 1);
      }
    }

    .messenger__header__avatar__wrap{
      position: relative;
    }

    &__avatar {
      border-radius: 50%;
      background-color: transparent;
      align-self: stretch;
      z-index: 0;
      display: flex;
      width: 32px;
      height: 32px;
      margin: auto 0;
      
      @include mobile {
        width: 24px;
        height: 24px;
      }
    }

    &__details {
      align-self: stretch;
      z-index: 0;
      display: flex;
      flex-direction: column;
      font-family: 'Inter-SemiBold', sans-serif;
      justify-content: center;
      flex: 1;
      flex-basis: 20px;
      margin: auto 0;
    }

    &__name {
      font-family: 'Inter-SemiBold', sans-serif;
      color: rgba(65, 65, 65, 1);
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: -0.34px;
      
      @include mobile{
        font-size: 14px;
        font-family: 'Inter-Medium', sans-serif;
        color: rgba(34, 34, 34, 1);
      }
    }

    &__status {
      &-indicator {
        stroke-width: 2px;
        border-radius: 50%;
        align-self: flex-start;
        position: absolute;
        z-index: 0;
        display: flex;
        width: 10px;
        height: 10px;
        right: 0px;
        bottom: 0px;
        
        @include mobile{
          width: 5px;
          height: 5px;
          right: -2px;
          bottom: -1px;
        }
      }
    }

    &__action-button {
      border-radius: 10px;
      background-color: #f5f5f5;
      align-self: stretch;
      z-index: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #000;
      letter-spacing: -0.3px;
      justify-content: center;
      height: 100%;
      padding: 0 10px;
      font: 500 15px/1.4 Inter, sans-serif;
    }

    &__action-icon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 20px;
      align-self: stretch;
      margin: auto 0;
    }

    &__action-text {
      align-self: stretch;
      margin: auto 0;
    }

    &__message-button {
      align-self: stretch;
      z-index: 0;
      display: flex;
      min-height: 36px;
      align-items: center;
      overflow: hidden;
      justify-content: center;
      width: 36px;
      margin: auto 0;
      background-color: rgba(241, 241, 241, 1);
      border-radius: 8px;

      @include mobile{
          background-color: transparent;
      }

      &:hover {
        background-color: rgba(241, 241, 241, 0.7);
      }
    }

    &__message-icon {
      aspect-ratio: 5;
      object-fit: contain;
      object-position: center;
      width: 16px;
      align-self: stretch;
      margin: auto 0;
    }

    &__visually-hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    &__activity-mobile{
      padding: 14px 25px;
      border-top: 1px solid rgba(241, 241, 241, 1);
      position: relative;

      &__currentStep{
        display: flex;
        align-items: center;
        gap: 22px;
        cursor: pointer;

        &__openIcon{
          transform: rotate(90deg);
          margin-left: auto;
        }

        &__block{
          background: rgba(34, 34, 34, 1);
          width: 24px;
          height: 24px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          span{
            color: #fff;
            font-family: 'Inter-Medium', sans-serif;
            font-size: 14px;
            line-height: 12px;
          }
        }

        &__title{
          font-family: 'Inter-Medium', sans-serif;
          font-size: 14px;
        }
      }

      &__allSteps{
        padding: 12px 25px;
        left: 0;
        width: 100%;
        position: absolute;
        top: 100%;
        transition: 0.5s all;
        background: #fff;
        border-bottom: 1px solid rgba(241, 241, 241, 1);
        z-index: 2;
      }
    }
  }

  &__order-status {
    width: 100%;
    padding: 0.8vh;
    background-color: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;

    @media (max-width: 641px) {
      border-bottom: 0;
    }

    & span {
      font-size: 1.6vh;
      font-weight: bold;
      width: 90%;
      flex-wrap: wrap;
      @media (max-width: 641px) {
        font-size: 1.7vh;
      }

    }

    &__buttons span {
      & button {
        font-size: 1.6vh;
        width: calc(100% + 0.5vw);
        margin-bottom: 2.5%;
        padding: 0.5vh 1vw;


        @media (max-width: 641px) {
          font-size: 1.6vh;
        }

      }
    }

  }

  // Order info
  &__order__info {
    width: 348px;
    padding: 12px;
    border-left: 1px solid rgba(241, 241, 241, 1);

    &__wrap {
      padding: 12px;

      &__activity_block{
        padding: 36px 20px;
      }

      &__title {
        font-family: 'Inter-SemiBold', sans-serif;
        font-size: 21px;
      }

      // custom styles for steps antd
      &__activitysteps {
        .ant-steps-item-title {
          font-family: 'Inter-Medium', sans-serif;
          font-size: 14px;
          color: rgba(34, 34, 34, 1) !important;
        }

        .ant-steps-item-tail {
          top: -9px !important;
          height: 145% !important;

          &:after {
            background-color: #000 !important;
            width: 2px !important;
          }
        }

        // active step
        .ant-steps-item-active, .ant-steps-item-finish{
          .ant-steps-item-icon {
            width: 24px;
            height: 24px !important;
            background: rgba(34, 34, 34, 1) !important;
            border-radius: 5px;
            margin-left: 4px;
            margin-top: 5px;
            border: none;
          }
          .ant-steps-item-title {
            color: rgba(34, 34, 34, 1) !important;
          }
        }

        .ant-steps-item-icon {
          width: 24px;
          height: 24px !important;
          background: rgba(241, 241, 241, 1) !important;
          border-radius: 5px;
          margin-left: 4px;
          margin-top: 5px;
          border: none;

          .ant-steps-icon {
            width: 100% !important;
            height: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Inter-Medium', sans-serif;
            font-size: 14px;
          }
        }

        .ant-steps-item-wait{
          .ant-steps-icon{
            color: rgba(160, 160, 160, 1) !important;
          }
        }

        .ant-steps-item-wait, .ant-steps-item-process {
          .ant-steps-item-title{
            color: rgba(160, 160, 160, 1) !important;
          }

          .ant-steps-item-title {
            color: rgba(34, 34, 34, 1) !important;
          }

          .ant-steps-item-tail {
            &:after {
              background-color: rgba(241, 241, 241, 1) !important;
              width: 2px !important;
            }
          }
        }

        &__description {
          margin-top: 5px;

          &__text {
            font-family: 'Inter-Medium', sans-serif;
            font-size: 14px;
          }

          &__btns {
            display: flex;
            align-items: center;
            margin-top: 16px;

            &__nextBtn {
              background: rgba(241, 241, 241, 1);
              padding: 6px;
              font-family: 'Inter-Medium', sans-serif;
              font-size: 14px;
              flex: 1;
              color: rgba(34, 34, 34, 1);
              text-align: center;
              max-width: 60%;
              border-radius: 8px;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }

            &__cancelBtn {
              color: rgba(255, 59, 48, 1);
              flex: 1;
              font-family: 'Inter-Medium', sans-serif;
              font-size: 14px;
              text-align: center;
              border-radius: 8px;
              cursor: pointer;
            }
          }
        }
      }

      // order description
      &__description{
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 24px;

        &__btn{
          font-family: 'Inter-Medium', sans-serif;
          font-size: 14px;
          color: rgba(34, 34, 34, 1);
          background: rgba(241, 241, 241, 1);
          min-width: 117px;
          max-width: max-content;
          gap: 10px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 12px;
          margin-top: 16px;
          cursor: pointer;
        }
      }
      &__field {

        &__title{
          font-family: 'Inter-Regular', sans-serif;
          font-size: 14px;
          color: rgba(136, 136, 136, 1);
        }

        &__value {
          font-family: 'Inter-Regular', sans-serif;
          font-size: 14px;
          color: rgba(34, 34, 34, 1);
        }
      }

    }
  }

  // Footer
  &__footer {
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
    display: flex;
    width: 90%;
    min-height: 104px;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid rgba(241, 241, 241, 1);

    &__img-wrap{
      position: relative;
      width: 50px;
      height: 50px;
      margin-left: 5px;

      &__closeIcon{
        position: absolute;
        top: -5px;
        right: -5px;
        background: #CECECE;
        width: 13px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        opacity: 0.9;
        z-index: 10;
        outline: #3a3a3a 1px solid;

        &:hover{
          opacity: 1;
        }

        svg{
          width: 8px;
          height: 8px;
        }
      }
    }

    &__file-wrap{
      display: flex;
      align-items: flex-start;
      gap: 10px;
      padding: 10px;

      &__file{
        width: 25px;
        position: relative;

        &__closeIcon{
          position: absolute;
          top: -9px;
          right: -2px;
          background: #CECECE;
          width: 13px;
          height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          cursor: pointer;
          opacity: 0.9;
          z-index: 10;
          outline: #3a3a3a 1px solid;

          &:hover{
            opacity: 1;
          }

          svg{
            width: 8px;
            height: 8px;
          }
        }
      }

      &__description{
        font-family: 'Inter-Medium', sans-serif;
        font-size: 14px;
      }
    }

    &__img{
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }

    &__message {
      width: 100%;
      color: #000;
      letter-spacing: -0.51px;
      padding: 10px;
      font-family: 'Inter-Medium', sans-serif;
      font-size: 14px;
      border: none;
      outline: none;
      resize: none;
      flex: 1;
      height: 47px;

      &::placeholder{
        color: rgba(136, 136, 136, 1);
      }

      &:focus{
        font-size: 14px !important;
      }
    }

    &__actions {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__images {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-left: 5px;

      & img {
        cursor: pointer;
        height: 20px;
      }
    }

    &__send-button {
      border-radius: 10px;
      background-color: #222;
      min-height: 36px;
      color: #fff;
      letter-spacing: -0.3px;
      padding: 8px 12px;
      font-family: 'Inter-Medium', sans-serif;
      font-size: 14px;
      cursor: pointer;
      margin-left: auto;

      &:hover {
        opacity: 0.8;
      }
    }

    @media (max-width: 991px) {
      &__message,
      &__send-button,
      &__actions {
        max-width: 100%;
        white-space: normal;
      }
    }
  }

  // Messages list
  &__list {
    flex: 1;
    overflow-x: hidden;
    width: 100%;
    padding: 25px 32px;

    &__loader{
      height: 50px;
    }

    &__elem {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: start;

      &:last-child::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 10px;
      }
    }
  }

  &__empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Inter-Regular", monospace;
    flex: 1;
    background: #ededed;
    width: 100%;
  }

}

input[type='text']:focus,
input[type='number']:focus,
textarea:focus {
  font-size: 16px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

@import '../../scss/mixins';

.modal{
  padding: 0 !important;
  border-radius: 16px !important;
  
  @include mobile{
      border-radius: 16px 16px 0 0 !important;
    overflow: hidden !important;
  }
}

.wrapper{
  background: #000;
}

.modalContent{
  background: #fff;
  padding: 32px;
  
  @include mobile{
      padding: 24px;
  }
}

.title{
  font-family: 'Inter-SemiBold';
  font-size: 21px;
  color: rgba(34, 34, 34, 1);
}

.description{
  font-family: 'Inter-Regular';
  font-size: 14px;
  color: rgba(34, 34, 34, 1);
}

.footer{
  margin-top: 24px;
 .btns{
   display: flex;
   align-items: center;
   gap: 16px;

   .cancelBtn{
     font-family: 'Inter-Medium', sans-serif;
     font-size: 14px;
     color: rgba(34, 34, 34, 1);
     flex: 1;
     padding: 8px 0;
     text-align: center;
     cursor: pointer;
   }

   .submitBtn{
     font-family: 'Inter-Medium', sans-serif;
     font-size: 14px;
     color: #fff;
     flex: 1;
     background: rgba(34, 34, 34, 1);
     padding: 8px 0;
     text-align: center;
     border-radius: 8px;
     cursor: pointer;
   }
 }
}

.drawerBody{
  overflow-x: hidden !important;
  padding: 0 !important;
}

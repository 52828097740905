.mobile-modal-withdraw {
  border-radius: 16px !important;
  padding: 32px 32px !important;
}
.modal-deposit {
  font-family: "Inter-Regular", sans-serif;

  &__input {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 7px;

    &__label {
      font-family: "Inter-Regular", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: -0.02em;
      color: rgba(136, 136, 136, 1);
    }

    &__field {
      height: 36px;
      width: 300px;
      padding: 0 12px 0 12px;
      border-radius: 8px;
      border: none;
      outline: none;
      background-color: rgba(241, 241, 241, 1);
      font-size: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: max-content; // fix bag with translating

    //@media (max-width: 641px) {
    //  height: 80vh;
    //}

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & span {
        font-family: "Inter-Bold", sans-serif;
        font-size: 27px;
        font-weight: 700;
        line-height: 32.4px;
        letter-spacing: -0.02em;
      }
    }

    &__headerRight {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-right: 9px;
      // padding-bottom: 16px;
      & > h4 {
        color: rgba(136, 136, 136, 1);
        font-weight: 500;
        font-family: "Inter-Medium", sans-serif;
        font-size: 14px;
      }
    }

    &__wrapper {
      display: flex;
      width: 100%;
      height: 310px;
      gap: 46px;
      align-items: center;

      @media (max-width: 641px) {
        flex-direction: column-reverse;
        justify-content: start;
        gap: 20px;
      }
    }

    &__info {
      display: flex;
      width: 50%;
      flex-direction: column;

      @media (max-width: 641px) {
        width: 80%;
      }
    }

    &__qr {
      display: flex;
      width: 40%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      @media (max-width: 641px) {
        width: 80%;
      }

      &--title {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 320px;
        & h4 {
          font-family: "Inter-Medium", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
          color: rgba(136, 136, 136, 1);
        }
      }
    }
  }
}




// MOBILE DRAWER STYLES


.mobile-modal-withdraw {
  border-radius: 16px !important;
  padding: 32px 30px !important;
}
.mobile-modal-deposit {
  font-family: "Inter-Regular", sans-serif;

  &__input {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 7px;

    &__label {
      font-family: "Inter-Regular", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: -0.02em;
      color: rgba(136, 136, 136, 1);
    }

    &__field {
      height: 36px;
      width: 300px;
      padding: 0 12px 0 12px;
      border-radius: 8px;
      border: none;
      outline: none;
      background-color: rgba(241, 241, 241, 1);
      font-size: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: max-content;
    padding-top: 24px;

    &__header {
      display: flex;
      justify-content: start;
      flex-direction: column;

      & span {
        font-family: "Inter-Medium", sans-serif;
        font-size: 21px;
        font-weight: 600;
        line-height: 32.4px;
        letter-spacing: -0.02em;
      }
    }

    &__wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 641px) {
        flex-direction: column-reverse;
        justify-content: start;
        gap: 20px;
      }
    }

    &__info {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    &__qr {
      display: flex;
      width: 40%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      &--title {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 88vw;
        padding-top: 8px;
        padding-bottom: 24px;
        & h4 {
          font-family: "Inter-Medium", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
          color: rgba(136, 136, 136, 1);
        }
      }
    }
  }
}

.ant-drawer-modal-content-deposit {
  @media screen and (min-width: 768px) and (max-width: 896px) {
    .mobile-modal-deposit__content__header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    .mobile-modal-deposit__content__qr--title {
      display: flex;
      align-items: center;
      width: 38%;
      flex-direction: row;
      gap: 5px;
      padding-top: 0px;
      & > h4 {
        font-family: "Inter-Medium", sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: rgba(136, 136, 136, 1);
        letter-spacing: -0.02em;
        line-height: 19.6px;
      }
    }
  }
}
.ant-drawer-header {
  position: absolute;
  right: 0;
  top: 26px;
}
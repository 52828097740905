.g-button {
  position: relative;
  flex-basis: 100%;

  padding: 0.5vh;

  outline: none;
  border: none;
  border-radius: 6px;

  background: transparent;

  cursor: pointer;

  transition: 0.2s;
  font-family: "Inter-Regular", monospace;
  font-size: 0.5vh;
  font-weight: 600;
  line-height: 0.5vh;
  letter-spacing: 0;
  text-align: center;
  min-height: 3vw;
  max-width: 12vw;

  @media (max-width: 641px) {
    min-height: 8vw;
    max-width: 30vw;
  }

  & span {
    text-align: center;
    line-height: 1;
    font-size: 1.5vh;

    @media (max-width: 641px) {
      font-size: 2vh;
    }
  }

  &_tiny {
    padding: 0.5vh 0.8vh;
    max-width: 15vw;
    min-height: 30px;
    @media (max-width: 641px) {
      max-width: 40vw;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

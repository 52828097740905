.input__price {
  &__amount {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 30px;

    &__input-wrapper {
      display: flex;
      width: 100%;
      color: rgba(34, 34, 34, 1);
      font-size: 27px;
      font-weight: 700;
      font-family: "Inter-Bold", sans-serif;

      //@media (max-width: 641px) {
      //  padding-left: 50px;
      //}
    }

    &__input {
      background-color: transparent;
      border: none;
      outline: none;
      color: rgba(34, 34, 34, 1);
      font-size: 27px;
      font-weight: 700;
      font-family: "Inter-Bold", sans-serif;
      width: 100%;

      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus{
        font-size: 27px;
      }
    }

    &__button-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 5px;
      flex-wrap: wrap;
      margin-top: 5px;

      &__amount {
        background-color: rgba(241, 241, 241, 1);
        height: 36px;
        border-radius: 8px;
        padding: 0 12px;
        color: #222;
        font-size: 14px;
        font-family: "Inter-Medium", sans-serif;
      }
    }

    &__button {
      background-color: rgba(241, 241, 241, 1);
      height: 36px;
      border-radius: 8px;
      padding: 0 12px;
      color: #222;
      font-size: 14px;
      font-family: "Inter-Regular", sans-serif;
    }
  }
}
:root {
  --dialog-opened: flex;
}

.home {
  display: flex;
  flex-direction: row;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  width: 100%;
  height: 100dvh;

  @media(max-width: 641px) {
    flex-direction: column-reverse;
    justify-content: start;
    background: white;
    position: relative;
    width: 100%;
  }

  &__nav {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: #000;
    letter-spacing: -0.3px;
    line-height: 140%;
    font-family: Inter, sans-serif;
    width: 20vw;
    max-width: 290px;
    min-width: 203px;
    background-color: rgba(248, 248, 248, 1);

    @media (max-width: 641px) {
      position: sticky;
      bottom: 0;
      width: 100%;
      height: 70px;
      max-width: none;
      padding-bottom: 5px;
      z-index: 1;
    }

    &__header {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.8);
      letter-spacing: -0.38px;
      line-height: 120%;
      margin: 25px 0 25px 30px;
      font-family: "Inter-Regular", sans-serif;

      @media (max-width: 641px) {
        display: none;
      }

      & h1 {
        font-size: 14px;
        font-weight: 400;
      }

      & img {
        aspect-ratio: 1;
        width: 16px;
      }
    }

    &__title {
      margin: 0;
      padding: 10px;
      font-weight: 350;
      user-select: none;
    }

    &__list {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding: 0;
      margin: 0;
      position: relative;
      height: 100%;

      @media (max-width: 641px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }

      &__el {
        margin: 2px 0;

        &:nth-child(3) {
          margin-bottom: 25px;
        }

        &:nth-child(6) {
          margin-top: auto;

          .home__nav__item__icon {
            border-radius: 100%;
          }
        }

        @media (max-width: 641px) {
          margin: 0;

          &:nth-child(5), &:nth-child(7) {
            display: none;
          }

          &:nth-child(3), &:nth-child(6) {
            margin: 0;
          }
        }
      }
    }

    .selected {
      background-color: #fff;

      @media (max-width: 641px) {
        opacity: 1;
        background-color: transparent;
      }
    }

    &__item {
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      margin: 0 20px;
      cursor: pointer;

      @media (max-width: 641px) {
        opacity: 0.4;
        flex-direction: column;
        gap: 0;
        margin: 0;
        padding: 0;
      }

      @media (max-width: 991px) {
        margin: 0 10px;
      }

      @media (hover: hover) {
        &:hover {
          background-color: #fff;
        }
      }

      &__icon {
        aspect-ratio: 1;
        width: 16px;
        object-fit: cover;
      }

      &__label {
        font-weight: 500;
        font-family: Inter-Regular, sans-serif;
        letter-spacing: -2%;
        flex: 1;
        user-select: none;
        font-size: 14px;

        @media (max-width: 641px) {
          font-size: 12px;
        }
      }

      &__count {
        font-weight: 400;
        font-size: 12px;
        font-family: "Inter-Regular", sans-serif;

        @media (max-width: 641px) {
          display: none;
        }
      }
    }

  }

  &__content {
    flex: 1;
    height: 100vh;
    overflow: hidden;
    @media(max-width: 641px) {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
}

.pageInfo__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: -0.3px;
  line-height: 140%;
  justify-content: space-between;
  padding: 22px 38px 34px;

  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }

  @media (max-width: 641px) {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__gobackWrapper {
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    & button img {
      aspect-ratio: 1;
      width: 16px;
    }

    @media (max-width: 641px) {
      display: flex;
    }
  }

  &__navigationWrapper {
    display: flex;
    gap: 10px;
    padding: 10px;
    align-items: center;

    @media (max-width: 991px) {
      white-space: initial;
    }

    &__icon {
      aspect-ratio: 1;
      object-fit: fill;
      object-position: center;
      width: 20px;
      align-self: flex-start;
      background: transparent;
    }

    &__item {
      font-family: "Inter-Medium", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
      letter-spacing: -0.02em;
    }

    &__separator {
      font-family: 'Inter-Medium', sans-serif;
      align-self: flex-start;
      font-size: 14px;
    }
  }
}
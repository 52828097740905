@font-face {
  font-family: "Inter-Light";
  src: url("./assets/fonts/Inter/Inter_18pt-Light.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter/Inter_18pt-Bold.ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/fonts/Inter/Inter_18pt-Medium.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets/fonts/Inter/Inter_18pt-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter/Inter_18pt-Regular.ttf");
}

:root {
  --color-blue: #0066FF;
  --color-blue-hover: #288df5;
  --color-blue-darker: #2867a8;
  --color-gray: #bcbcbc;
  --color-gray-hover: #d3d3d3;
  --color-green: #68bd85;
  --color-green-contrast: #71ea6e;
  --color-red: #d85c5c;
  --color-red-contrast: #b22424;
  --color-white: #f6f6f6;
  --color-placeholder: #838383;

  --bg-color: #FFFFFF;

  --navbar-height: 70px
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

html {
  height: 100dvh;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100dvh;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  background: var(--bg-color);
}

button,
input {
  font-family: "Nunito", sans-serif;
}

button {
  border: none;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
}

p {
  padding: 0;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9747FF;
}

.invisible {
  visibility: hidden;
}

.link {
  position: relative;

  color: var(--color-blue);
  font-weight: 500;

  transition: 0.2s;

  cursor: pointer;

  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background-color: var(--color-blue);

    transition: 0.2s;
  }

  &:hover {
    color: var(--color-blue-hover);

    &::after {
      background-color: var(--color-blue-hover);
    }
  }
}

.link_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.violet-bg {
  background-color: #694ef0 !important;
}
.violet-bg_hover:hover {
  background-color: #543fc0 !important;
}
.gray-bg {
  background-color: #443f5e !important;
}
.gray-bg_hover:hover {
  background-color: #36324a !important;
}
.white-bg {
  background-color: white !important;
}

.white-border {
  border: 1px solid white;
}

.blue-bg {
  background-color: var(--color-blue-hover) !important;
}

.blue-bg_hover:hover {
  background-color: var(--color-blue) !important;
}

.blue-border {
  border: 1px solid !important;
  border-color: var(--color-blue) !important;
}

.blue-border_hover:hover {
  border-color: var(--color-blue-hover) !important;
}

.red-bg {
  background-color: var(--color-red) !important;
}

.red-bg_contrast {
  background-color: var(--color-red-contrast) !important;
}

.red-border {
  border: 1px solid !important;
  border-color: var(--color-red) !important;
}

.red-border_contrast {
  border-color: var(--color-red-contrast) !important;
}

.green-bg {
  background-color: var(--color-green) !important;
}

.green-bg_contrast {
  background-color: var(--color-green-contrast) !important;
}

.green-border {
  border: 1px solid !important;
  border-color: var(--color-green) !important;
}

.green-border_contrast {
  border-color: var(--color-green-contrast) !important;
}

.ReactModal__Overlay {
  z-index: 999;
}

.ant-table-thead {

  & th {
    background: transparent !important;

    &:before {
      width: 0 !important;
    }
  }
}

.ant-table-cell{
  padding-left: 0 !important;
}

.table__paginator{
  //background: #000;

  .ant-pagination-item{
    border-color: rgb(136, 136, 136);
    a{
      color: rgb(136, 136, 136) !important;
    }

    &:hover{
      border-color: rgb(136, 136, 136);
    }
  }
  .ant-pagination-item-active{
    border-color: rgb(136, 136, 136);
  }
}
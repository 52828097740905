@mixin flex-column {
  display: none;
  align-items: center;
  flex-direction: column;
}

@mixin flex-row {
  display: none;
  align-items: center;
  flex-direction: row;
}

.browse {
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: start;
  overflow: scroll;

  // Page header
  &__header {
    background: #fff;
    width: 100%;
    padding: 30px 64px;
    font-size: 27px;
    color: #000;
    font-weight: 500;
    letter-spacing: -0.54px;
    line-height: 1.2;
    font-family: "Inter-Bold", sans-serif;

    @media (max-width: 991px) {
      max-width: 100%;
      white-space: initial;
      padding: 0 20px;
    }

    @media (max-width: 641px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

  }

  &__tab {
    &-container {
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      width: 100%;
      gap: 29px;
      font-size: 14px;
      color: #a0a0a0;
      font-weight: 500;
      letter-spacing: -0.28px;
      line-height: 1.4;
      flex-wrap: wrap;
      padding: 0 64px;

      @media (max-width: 991px) {
        max-width: 100%;
        white-space: initial;
        padding: 0 20px;
      }
    }

    &--active {
      align-items: start;
      border-bottom: 1px solid #222;
      display: flex;
      min-height: 36px;
      color: #222;
      justify-content: start;

      @media (max-width: 991px) {
        white-space: initial;
      }
    }

    &--inactive {
      display: flex;
      min-height: 36px;
      align-items: start;
      justify-content: start;
      padding: 8px 0;

      & img {
        opacity: 0.65;
      }

      @media (max-width: 991px) {
        white-space: initial;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: center;
      background: none;
      border: none;
      cursor: pointer;
      font-size: inherit;
      color: inherit;
      font-family: "Inter-Regular", sans-serif;

      @media (max-width: 991px) {
        white-space: initial;
      }
    }

    &__icon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 16px;
      align-self: stretch;
      margin: auto 0;
    }

    &__text {
      align-self: stretch;
      margin: auto 0;
    }
  }

  &__visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  // Search and category change
  &-search {
    &__container {
      display: flex;
      margin-top: 30px;
      width: 100%;
      gap: 20px;
      justify-content: space-between;
      padding-left: 64px;

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }

      @media (max-width: 641px) {
        padding-left: 20px;
        height: fit-content;
      }
    }

    &__search {
      &-wrapper {
        border-radius: 10px;
        // background-color: rgba(0, 0, 0, 0.03);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-content {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.03);
        display: flex;
        gap: 10px;
        justify-content: center;
        padding: 10px;

        & input {
          background: transparent;
          border: none;
          font-size: 16px;
          outline: none;
          font-family: "Inter-Regular", sans-serif;
        }
      }

      &-text {
        letter-spacing: -0.3px;
        flex: 1;
        width: 15vw;

        @media (max-width: 641px) {
          width: 50vw;
        }
      }
    }

    &__icon-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      justify-content: center;
    }

    &__icon {
      aspect-ratio: 1;
      width: 16px;
    }

    &__action-group {
      display: flex;
      gap: 10px;

      @media (max-width: 641px) {
        flex-wrap: wrap;
      }
    }

    &__action-button {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      &-content {
        display: flex;
        align-items: start;
        border-radius: 10px;
        gap: 10px;
        padding: 10px;
      }

      &-text {
        color: #000;
        letter-spacing: -0.3px;
        font: 500 15px/140% "Inter-Regular", sans-serif;
      }
    }


    &__category {
      &-selector {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.03);
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-content {
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.03);
          display: flex;
          gap: 3px;
          padding: 3px;
        }
      }

      &-option {
        border-radius: 7px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
        cursor: pointer;

        &-content {
          border-radius: 7px;
          background-color: #fff;
          display: flex;
          gap: 10px;
          justify-content: center;
          padding: 7px 10px;
        }

        &-text {
          color: rgba(0, 0, 0, 0.8);
          letter-spacing: -0.15px;
        }
      }
    }

    &__inactive-category-option {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      cursor: pointer;

      &-content {
        display: flex;
        gap: 10px;
        justify-content: center;
        padding: 7px 10px;
      }

      &-text {
        color: rgba(0, 0, 0, 0.2);
        letter-spacing: -0.3px;
      }
    }

  }

  // Service search card
  &__service-search-card {
    display: flex;
    max-width: 348px;
    flex-direction: column;
    justify-content: start;

    &__container {
      margin-top: 6vh;
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 40px;
      justify-content: start;
      flex-wrap: wrap;
      padding: 0 64px 20px;
      z-index: 0;

      @media (max-width: 991px) {
        gap: 30px;
      }

      @media (max-width: 641px) {
        margin-top: 1vh;
        padding: 0 20px 20px;
      }
    }

    &__image {
      aspect-ratio: 1.32;
      object-fit: contain;
      object-position: center;
      width: 100%;
    }

    &__details {
      display: flex;
      margin-top: 16px;
      width: 100%;
      flex-direction: column;
      justify-content: start;
    }

    &__user-info {
      display: flex;
      min-height: 40px;
      width: 100%;
      align-items: center;
      gap: 12px;
      justify-content: center;
    }

    &__avatar-wrapper {
      display: flex;
      flex-direction: column;
      border-radius: 1000px;
      align-self: stretch;
      position: relative;
      aspect-ratio: 1;
      width: 36px;
      margin: auto 0;
    }

    &__avatar-image {
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    &__status-indicator {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #34c759;
      border-radius: 50%;
      width: 11px;
      height: 11px;
      border: 2px solid #fff;
    }

    &__user-details {
      align-self: stretch;
      display: flex;
      min-width: 240px;
      flex-direction: column;
      letter-spacing: -0.28px;
      justify-content: center;
      flex: 1;
      flex-basis: 0;
      margin: auto 0;
      font: 500 14px/1.4 "Inter-Regular", sans-serif;
    }

    &__user-name {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      align-self: stretch;
      flex: 1;
      width: 100%;
      gap: 16px;
      color: #414141;
    }

    &__rating-wrapper {
      align-self: start;
      display: flex;
      align-items: center;
      gap: 4px;
      justify-content: start;
    }

    &__rating-icon {
      aspect-ratio: 1;
      object-fit: contain;
      object-position: center;
      width: 16px;
      align-self: stretch;
      margin: auto 0;
    }

    &__rating-score {
      color: #414141;
      align-self: stretch;
      margin: auto 0;
    }

    &__rating-count {
      color: #a0a0a0;
      align-self: stretch;
      margin: auto 0;
    }

    &__price {
      color: #585858;
      letter-spacing: -0.28px;
      align-self: stretch;
      margin: auto 0;
      font: 500 14px/1.4 "Inter-Regular", sans-serif;
    }

    &__description {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      color: var(--100, #222);
      text-overflow: ellipsis;
      letter-spacing: -0.34px;
      margin-top: 12px;
      font: 600 17px/24px "Inter-Regular", sans-serif;
    }
  }

  // Profile search card
  &__profile-search-card {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 350px;
    height: 250px;
    border: 1px solid rgba(241, 241, 241, 1);
    border-radius: 8px;
    cursor: pointer;

    @media (max-width: 991px) {
      margin-top: 10px;
    }

    &__container {
      margin-top: 6vh;
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 30px;
      justify-content: start;
      flex-wrap: wrap;
      padding: 0 64px;
      z-index: 0;

      @media (max-width: 991px) {
        gap: 20px;
      }

      @media (max-width: 641px) {
        margin-top: 1vh;
        padding-bottom: 20px;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;

      &__top {
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;

        &__message-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          min-height: 36px;
          width: 110px;
          padding: 0;
          border-radius: 8px;
          background-color: rgba(241, 241, 241, 1);
          font: 500 14px/1.4 'Inter-Regular', sans-serif;
          letter-spacing: -0.28px;
          white-space: nowrap;
          color: #222;

          &:hover {
            background-color: rgba(0, 0, 0, 0.15);
          }

          &__icon {
            width: 16px;
            height: 16px;
            object-fit: contain;
          }
        }
      }

      &__bottom {
        width: 100%;
        justify-content: start;
        font-family: "Inter-Regular", sans-serif;

        & h3 {
          font-size: 17px;
          font-weight: 600;
          line-height: 23.8px;
          letter-spacing: -0.02em;
          text-align: left;
        }

        & h4 {
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
          text-align: left;
          color: rgba(160, 160, 160, 1);
        }
      }

    }

    &__work {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      border: 1px solid rgba(241, 241, 241, 1);
      background-color: rgba(241, 241, 241, 1);
      border-radius: 8px;

      & span {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.6;
        font-family: "Inter-Regular", sans-serif;
      }
    }

    &__user-info {
      display: flex;
      gap: 10px;
      line-height: 140%;
    }

    &__avatar {
      aspect-ratio: 1;
      object-fit: cover;
      width: 40px;
      border-radius: 50%;
      margin: auto 0;

      &__status-indicator {
        stroke-width: 2px;
        border-radius: 50%;
        align-self: flex-start;
        position: absolute;
        z-index: 0;
        display: flex;
        aspect-ratio: 1;
        width: 10px;
        left: 28px;
        bottom: 5px;
      }
    }

    &__user-details {
      display: flex;
      flex-direction: column;
    }

    &__name-wrapper {
      display: flex;
      gap: 10px;
    }

    &__name-container {
      display: flex;
      gap: 5px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 600;
      letter-spacing: -0.15px;
    }

    &__icon {
      aspect-ratio: 1;
      object-fit: contain;
      width: 16px;
      margin: auto 0;
    }

    &__user-name {
      font-family: "Inter-Regular", sans-serif;
    }

    &__user-level {
      border-radius: 5px;
      background-color: rgba(175, 82, 222, 0.1);
      align-self: start;
      color: #af52de;
      white-space: nowrap;
      letter-spacing: -0.13px;
      padding: 1px 5px;
      font: 400 13px "Inter-Regular", sans-serif;

      @media (max-width: 991px) {
        white-space: initial;
      }
    }

    &__rating-container {
      display: flex;
      gap: 5px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.4);
      font-weight: 400;
      letter-spacing: -0.3px;
    }

    &__rating-text {
      font-family: "Inter-Regular", sans-serif;
    }

    &__price-info {
      color: rgba(0, 0, 0, 0.8);
      text-overflow: ellipsis;
      letter-spacing: -0.13px;
      align-self: start;
      font: 400 13px/18px "Inter-Regular", sans-serif;
    }

    &__description {
      color: #000;
      text-overflow: ellipsis;
      letter-spacing: -0.38px;
      margin-top: 16px;
      font: 600 19px/23px "Inter-Regular", sans-serif;
    }

    &__skills-container {
      display: flex;
      margin-top: 16px;
      padding-right: 66px;
      gap: 5px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 500;
      letter-spacing: -0.3px;
      line-height: 140%;

      @media (max-width: 991px) {
        padding-right: 20px;
      }
    }

    &__skill-tag {
      font-family: "Inter-Regular", sans-serif;
      border-radius: 999px;
      white-space: nowrap;
      padding: 5px 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      @media (max-width: 991px) {
        white-space: initial;
      }
    }

    &__more-skills {
      font-family: "Inter-Regular", sans-serif;
      border-radius: 999px;
      padding: 5px 10px;
    }
  }



  &__body {
    @include flex-column;
    width: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;

    @media (max-width: 641px) {
      margin-left: 5%;
      margin-top: 5%;
    }

    &-title {
      @include flex-row;
      width: 100%;
      margin-left: 2.5%;
      opacity: 0.8;
      font-size: 2vh;
    }

    &__content {
      @include flex-column;
      align-items: start;
      width: 100%;
      margin-top: 2%;

      @media (max-width: 641px) {
        margin-top: 5%;
      }

      &-el {
        @include flex-row;
        width: 80%;
        height: 100%;
        justify-content: start;
        background: #FCFCFC;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.30);
        border-radius: 6px;
        margin-bottom: 4%;

        @media (max-width: 641px) {
          width: 95%;
          margin-bottom: 10%;
          border: 1px dashed black;
        }

      }

    }

  }

  &__search-card {
    @include flex-column;
    width: 95%;
    height: 95%;

    &__header {
      @include flex-row;
      width: 90%;
      margin-top: 2%;
      margin-bottom: 3.5%;

      @media (max-width: 641px) {
        margin-top: 1%;
      }

      &-avatar {
        aspect-ratio: 1 / 1;
        height: 4vh;
        border-radius: 100%;

        &-img {
          height: 100%;
          background-size: cover;
          border-radius: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          & object, & object img {
            aspect-ratio: 1 / 1;
            height: 4vh;
            border-radius: 100%;
          }
        }
      }

      &-info {
        @include flex-column;
        align-items: start;
        width: 100%;
        margin-left: 3%;

        @media (max-width: 641px) {
          margin-left: 7%;
          font-size: 2vh;
        }

        & #info-login {
          font-size: 2.1vh;

          @media (max-width: 641px) {
            font-size: 2.2vh;
          }

        }

        & div {
          margin-bottom: 0.5%;
        }

        &-tags {
          @include flex-row;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: start;
          margin-top: 0.5%;

          & div {
            @include flex-column;
            margin-right: 1.5%;
            margin-bottom: 1%;
            background-color: lightgrey;
            border-radius: 5px;

            @media (max-width: 641px) {
              margin-right: 5%;
              margin-bottom: 3.5%;
            }

            & span {
              font-size: 1.6vh;
              padding: 0.25vw;
            }
          }
        }

        &-rating {
          @include flex-row;
          width: 100%;
          justify-content: start;
          align-items: center;

          & img {
            margin-right: 0.5%;

            @media (max-width: 641px) {
              margin-right: 1.5%;
            }
          }
        }

      }

    }

    &__body {
      @include flex-column;
      align-items: start;
      width: 90%;
      margin-bottom: 5%;

      @media (max-width: 641px) {
        width: 95%;
      }

      & span {
        color: #288df5;
        margin-right: 17%;
        max-width: 5%;
        font-size: 1.8vh;

        @media (max-width: 641px) {
          font-size: 2vh;
        }

      }

      &-skills {
        @include flex-row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.5%;
        width: 100%;
        font-size: 1.5vh;
        flex-wrap: wrap;

        @media (max-width: 641px) {
          font-size: 2vh;
          flex-direction: column;
          align-items: start;
          margin-bottom: 5%;
        }

      }

      &-chars {
        @include flex-row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.5%;
        width: 100%;
        font-size: 1.5vh;

        @media (max-width: 641px) {
          font-size: 2vh;
          flex-direction: column;
          align-items: start;
          margin-bottom: 5%;
        }
      }

      &-prefs {
        @include flex-row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 1.5vh;

        @media (max-width: 641px) {
          font-size: 2vh;
          flex-direction: column;
          align-items: start;
        }
      }
    }

    &__footer {
      @include flex-row;
      width: 100%;
      justify-content: end;
      margin-bottom: 5%;

      @media (max-width: 641px) {
        margin-bottom: 8%;
        margin-right: 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      & button {
        width: 100%;
        font-size: 1.5vh;
      }

    }

  }

  &__modal__close-btn {
    @include flex-row;
    width: 100%;
    justify-content: end;
  }

  &__modal__content {
    @include flex-column;
    justify-content: start;
    margin-top: 5%;

    &__confirm-button {
      @include flex-row;
      width: 90%;
      justify-content: space-between;
      margin-top: 10%;

      @media (max-width: 641px) {
        @include flex-column;
        width: 80%;
        justify-content: center;
      }

      & button {
        margin-bottom: 5%;
      }
    }

    & h2 {
      margin-bottom: 5%;
    }

    & button {
      margin-top: 3.5%;
      width: 6vw;
      min-width: 100px;
    }

  }

  &__hashtags-search {
    @include flex-column;
    width: 100%;
    align-items: start;
    margin: 0.5% 0 0 10%;

    @media (max-width: 641px) {
      width: 95%;
      margin-top: 5%;
    }

    & h1 {
      margin-bottom: 2.5%;

      @media (max-width: 641px) {
        font-size: 3.5vh;
      }
    }

    & button {
      margin-top: 2%;
      min-width: clamp(150px, 7vw, 10vw);
      font-size: 1.7vh;
      margin-bottom: 1%;

      @media (max-width: 641px) {
        min-width: 30vw;
        font-size: 2vh;
      }
    }

    &__tags {
      @include flex-row;
      width: 100%;
      gap: 2.5%;
      flex-wrap: wrap;

      & button {
        min-width: clamp(100px, 5vw, 200px);
        margin-bottom: 10%;
        font-size: 1.5vh;

        @media (max-width: 641px) {
          min-width: 20vw;
          font-size: 2vh;
        }
      }
    }

  }

}
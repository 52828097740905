@mixin flex-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.settings {
  @include flex-column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__title {
    width: 90%;
    justify-content: start;
    margin-top: 2%;

    & h1 {
      font-size: 3.5vh;
    }
  }

  &__language-select {
    @include flex-column;
    align-items: start;
    width: 90%;
    margin-top: 5%;

    & h3 {
      font-size: 2vh;
    }

    &__els {
      @include flex-column;
      width: 100%;
      margin-top: 2.5%;
      align-items: start;

      @media (max-width: 641px) {
        margin-top: 5%;
      }

      & div {
        width: 7vw;
        height: 3.5vh;

        @media (max-width: 641px) {
          width: 25vw;
          height: 5vh;
        }

        & div span {
          font-size: 1.5vh;
        }
      }

    }
  }

}
@mixin flex-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.modal {
  &__close-btn {
    @include flex-row;
    width: 100%;
    justify-content: end;
  }

  &__content {
    @include flex-column;
    justify-content: start;
    margin-top: 5%;

    &__confirm-button {
      @include flex-row;
      width: 100%;
      justify-content: space-between;
      margin-top: 10%;

      @media (max-width: 641px) {
        @include flex-column;
        justify-content: center;
      }

      & button {
        margin-bottom: 5%;
        width: 100%;
        font-size: 1.7vh;

        @media (max-width: 641px) {
          font-size: 2vh;
        }
      }
    }

    &__avatar {
      aspect-ratio: 1 / 1;
      height: 6vh;
      border-radius: 100%;
      background-size: cover;
      margin-right: 3%;
    }

    &__change-avatar-btn {
      font-size: 1.6vh;
      margin-bottom: 3.5%;
      text-decoration: underline;
    }

    & h2, h3 {
      margin-bottom: 5%;
    }

    & input {
      margin-top: 3.5%;
      min-width: 200px;
    }

    & button {
      margin-top: 3.5%;
      width: 6vw;
      min-width: 100px;
    }

    & input {
      margin-bottom: 5%;
      height: 6vh;
      width: 14vw;
      border-radius: 5px;
      border: 1px solid gray;
      padding: 1vh;
      font-size: 16px;
    }

    &__hashtags-btns {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 3.5%;

      & button {
        min-width: 80px;
      }

      @media (max-width: 641px) {
        flex-direction: column;
        width: 100%;
        margin: 15% 10% 20% 10%;

        & button {
          min-width: 80px;
          margin-bottom: 5%;
        }
      }
    }

  }

}


@mixin flex-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.user-profile {
  display: flex;
  flex-direction: row;
  justify-content: start;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: "Inter-Regular", sans-serif;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 641px) {
    flex-direction: column;
  }

  // Main info
  &__main-info {
    display: flex;
    max-width: 400px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    font-family: Inter, sans-serif;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: rgba(241, 241, 241, 1);
    padding-right: 10px;

    @media (max-width: 641px) {
      max-width: none;
      padding-bottom: 20px;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    color: rgba(27, 31, 38, 0.72);
    margin-top: 40px;
    border-bottom: 1px solid rgba(241, 241, 241, 1);
    padding-left: 30px;
    padding-bottom: 40px;

    &__buttons {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: start;
      gap: 10px;

      &__message-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0;
        min-height: 36px;
        width: 240px;
        padding: 0;
        border-radius: 8px;
        background-color: #222;
        color: #fff;
        font: 500 14px/1.4 Inter, sans-serif;
        letter-spacing: -0.28px;
        white-space: nowrap;

        &:hover {
          opacity: 0.85;
        }

        &__icon {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      &__edit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        min-height: 36px;
        width: 240px;
        padding: 0;
        border-radius: 8px;
        background-color: rgba(241, 241, 241, 1);
        font: 500 14px/1.4 Inter, sans-serif;
        letter-spacing: -0.28px;
        white-space: nowrap;

        &:hover {
          opacity: 0.85;
        }

        &__icon {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      &__menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        padding: 0;
        border-radius: 8px;
        background-color: rgba(241, 241, 241, 1);

        &:hover {
          background-color: rgba(241, 241, 241, 0.7);
        }

        &__icon {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }
    }

    &__status-indicator {
      stroke-width: 2px;
      border-radius: 50%;
      align-self: flex-start;
      position: absolute;
      z-index: 0;
      display: flex;
      width: 15px;
      height: 15px;
      right: 3px;
      bottom: 5px;
    }
  }

  &__avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    aspect-ratio: 1;
    object-fit: cover;

    &-container {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      position: relative;
    }
  }

  &__user-info {
    margin-top: 20px;
    text-align: start;
    width: 100%;
  }

  &__name {
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.72px;
    line-height: 1.2;
    margin: 0;
  }

  &__username {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: -0.15px;
    opacity: 0.4;
    margin: 5px 0 0;
  }

  &__bio {
    margin-top: 40px;
    color: rgba(27, 31, 38, 0.72);
    padding-left: 30px;

    &-title {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: -0.3px;
      opacity: 0.4;
      margin: 0 0 16px;
    }

    &-headline {
      font-size: 17px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.34px;
      margin: 0 0 16px;
    }

    &-description {
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      margin: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
    gap: 20px;
    line-height: 1.4;
    flex-wrap: wrap;
    padding-left: 30px;

    &-item {
      flex: 1;
      min-width: 100px;
    }

    &-label {
      color: #000;
      font-weight: 500;
      letter-spacing: -0.3px;
      opacity: 0.4;
      margin: 0 0 5px;
      font-size: 13px;
    }

    &-value {
      color: rgba(27, 31, 38, 0.72);
      font-weight: 400;
      letter-spacing: -0.15px;
      margin: 0;
      font-size: 15px;
    }
  }

  &__skills {
    margin-top: 40px;
    padding-left: 30px;

    &-title {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.3px;
      line-height: 1.4;
      opacity: 0.4;
      margin: 0 0 20px;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    &-tag {
      border-radius: 999px;
      padding: 5px 10px;
      font-size: 15px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      border: 1px solid #efeefb;
      white-space: nowrap;
    }
  }
  // End of main info
  // Content section
  &__content-section {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-left: 30px;
    padding-bottom: 100px;

    &__services-n-portfolio {
      display: flex;
      max-height: 365px;
      width: 100%;
      flex-direction: column;
      font-family: "Inter-Regular", sans-serif;

      &:first-child {
        margin-top: 60px;

        @media (max-width: 641px) {
          margin-top: 20px;
        }
      }

      &:last-child {
        margin-top: 40px;
      }

      @media (max-width: 991px) {
        &__heading,
        &__card-container {
          max-width: 100%;
        }
      }

      &__heading {
        display: flex;
        width: 100%;
        justify-content: start;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        font-family: "Inter-Regular", sans-serif;

        &__title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          & span {
            color: rgba(34, 34, 34, 1);
            font-size: 27px;
            font-weight: 400;
            line-height: 32.4px;
            letter-spacing: -2%;
            font-family: "Inter-Bold", sans-serif;
            align-items: center;
          }

          & div {
            width: 4px;
            height: 4px;
            background-color: #222;
            border-radius: 100%;
            margin: 0 5px;
          }

          & h3 {
            font-size: 27px;
            font-weight: 500;
            line-height: 32.4px;
            letter-spacing: -0.03em;
          }
        }


        & button {
          color: #222;
          margin-left: 70%;
          height: 36px;
          border-radius: 8px;
          background-color: rgba(241, 241, 241, 1);
          align-items: center;
          display: flex;
          padding: 0 12px;

          @media (max-width: 641px) {
            margin-left: 30%;
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.15);
          }

          & img {
            aspect-ratio: 1;
            width: 16px;
            margin-left: 2px;
          }
        }
      }

      &__card-container {
        display: flex;
        margin-top: 20px;
        max-width: 100%;
        align-items: center;
        gap: 50px;
        justify-content: flex-start;
        overflow: hidden;
      }

      &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 345px;

        &__image {
          aspect-ratio: 1.35;
          object-fit: cover;
          width: 345px;
          border-radius: 10px;
        }

        &__content {
          display: flex;
          margin-top: 10px;
          width: 100%;
          flex-direction: column;
        }

        &__title {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          color: rgba(0, 0, 0, 1);
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.34px;
          font-family: "Inter-Regular", sans-serif;
        }

        &__price {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          color: #222;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: -0.3px;
          margin-top: 5px;
        }
      }
    }

    &__reviews {
      display: flex;
      width: 100%;
      flex-direction: column;
      font-family: "Inter-Regular", sans-serif;
      margin-top: 50px;

      &__heading {
        display: flex;
        width: 100%;
        justify-content: start;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        font-family: "Inter-Regular", sans-serif;

        &__title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          & img {
            aspect-ratio: 1;
            width: 24px;
          }

          & span {
            color: rgba(34, 34, 34, 1);
            font-size: 27px;
            font-weight: 400;
            line-height: 32.4px;
            letter-spacing: -2%;
            font-family: "Inter-Bold", sans-serif;
            align-items: center;
          }

          & div {
            width: 4px;
            height: 4px;
            background-color: #222;
            border-radius: 100%;
            margin: 0 5px;
          }

          & h3 {
            font-size: 27px;
            font-weight: 500;
            line-height: 32.4px;
            letter-spacing: -0.03em;
          }
        }


        & button {
          color: #222;
          margin-left: 74%;
          height: 36px;
          border-radius: 8px;
          background-color: rgba(241, 241, 241, 1);
          align-items: center;
          display: flex;
          padding: 0 12px;

          @media (max-width: 641px) {
            margin-left: 40%;
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.15);
          }

          & img {
            aspect-ratio: 1;
            width: 16px;
            margin-left: 2px;
          }
        }
      }

      &__card {
        border-radius: 16px;
        background: #f8f8f8;
        display: flex;
        max-width: 348px;
        height: fit-content;
        padding: 16px 16px 40px;
        flex-direction: column;
        letter-spacing: -0.28px;
        gap: 0;
        font: 500 14px "Inter-Regular", sans-serif;

        @media (max-width: 641px) {
          max-width: none;
          min-width: 75vw;
        }

        &:has(button) {
          padding-bottom: 16px;
        }

        &__container {
          display: flex;
          margin-top: 20px;
          max-width: 100%;
          gap: 50px;
          justify-content: flex-start;
          overflow: hidden;
        }

        &__header {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 8px;
          line-height: 1.4;
        }

        &__logo {
          aspect-ratio: 5;
          object-fit: contain;
          object-position: center;
          width: 80px;
          align-self: stretch;
          margin: auto 0;
        }

        &__reviewer-name {
          color: #414141;
          margin: auto 0;
          font-size: 14px;
          font-weight: 500;
          font-family: "Inter-Regular", sans-serif;
        }

        &__date {
          color: #a0a0a0;
          align-self: stretch;
          margin: auto 0;
        }

        &__content {
          color: #222;
          line-height: 20px;
          margin-top: 12px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp: 4;
          -webkit-box-orient: vertical;
        }

        &__show-more-btn {
          font-size: 14px;
          font-family: "Inter-Regular", sans-serif;
          font-weight: 500;
          color: rgba(34, 34, 34, 1);
          margin-right: auto;
          margin-top: 10px;
        }
      }
    }
  }
  // End of content section
  // Edit page
  &__edit-page {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__container {
      display: flex;
      flex-direction: column;
      margin: 40px auto;
      width: 65%;
      overflow-y: scroll;

      @media (max-width: 641px) {
        width: 100%;
        margin: 0;
      }
    }

    &__buttons {
      border-radius: 10px;
      display: flex;
      max-width: 200px;
      gap: 10px;
      letter-spacing: -0.3px;
      font: 500 15px/1.4 Inter, sans-serif;
    }

    &__button {
      align-self: stretch;
      border-radius: 10px;
      min-height: 36px;
      padding: 8px 10px;
      cursor: pointer;
      border: none;
      transition: background-color 0.3s ease;

      &--secondary {
        color: #222;
        background-color: transparent;

        &:hover {
          background-color: rgba(27, 31, 38, 0.1);
        }
      }

      &--primary {
        background-color: #222;
        color: #fff;

        &:hover {
          background-color: rgba(27, 31, 38, 0.9);
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      width: 70%;
      margin: 40px auto;

      @media (max-width: 641px) {
        width: fit-content;
        margin: 20px auto;
        flex-direction: column;
        gap: 20px;

        &:last-child {
          padding-bottom: 80px;
        }
      }

      &__header {
        display: flex;
        flex-direction: column;
        align-items: start;

        & h2 {
          font-family: "Inter-Regular", sans-serif;
          font-size: 21px;
          font-weight: 600;
          line-height: 25.2px;
          letter-spacing: -0.02em;
        }

        & p {
          font-family: "Inter-Regular", sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: rgba(136, 136, 136, 1);
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: start;
      }

      &__input {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 7px;

        &__label {
          font-family: "Inter-Regular", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
          color: rgba(136, 136, 136, 1);
        }

        &__field {
          height: 36px;
          width: 300px;
          padding: 0 12px 0 12px;
          border-radius: 8px;
          border: none;
          outline: none;
          background-color: rgba(241, 241, 241, 1);
          font-size: 16px;
        }
      }

      &__textarea {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 7px;

        &__label {
          display: flex;
          justify-content: space-between;

          & span {
            font-family: "Inter-Regular", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
            letter-spacing: -0.02em;
            color: rgba(136, 136, 136, 1);
          }
        }

        &__field {
          width: 300px;
          min-height: 60px;
          resize: vertical;
          padding: 12px;
          border-radius: 8px;
          border: none;
          outline: none;
          background-color: rgba(241, 241, 241, 1);
          font-size: 16px;
          font-family: "Inter-Regular", sans-serif;
        }
      }
    }

  }
  // End of edit page

  &__reviews {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 70px 100px;

    @media (max-width: 641px) {
      padding: 20px 20px 80px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 100%;

      &__title {
        display: flex;
        flex-direction: row;
        align-items: center;

        & img {
          aspect-ratio: 1;
          width: 24px;
        }

        & span {
          color: rgba(34, 34, 34, 1);
          font-size: 27px;
          font-weight: 400;
          line-height: 32.4px;
          letter-spacing: -2%;
          font-family: "Inter-Bold", sans-serif;
          align-items: center;
        }

        & div {
          width: 4px;
          height: 4px;
          background-color: #222;
          border-radius: 100%;
          margin: 0 5px;
        }

        & h3 {
          font-size: 27px;
          font-weight: 500;
          line-height: 32.4px;
          letter-spacing: -0.03em;
        }
      }

      &__buttons {
        display: flex;
        width: 100%;
        gap: 10px;
        flex-wrap: wrap;

        & button {
          height: 32px;
          min-width: 48px;
          border: 1px solid rgba(241, 241, 241, 1);
          border-radius: 1000px;
          background-color: rgba(241, 241, 241, 1);
          padding: 0 8px;
          color: #222;
        }
      }
    }

    &__card-container {
      display: flex;
      flex-direction: row;
      align-items: start;
      margin-top: 50px;
      gap: 35px;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &__services {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 70px 100px;

    @media (max-width: 641px) {
      padding: 20px 20px 80px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 100%;

      &__title {
        display: flex;
        flex-direction: row;
        align-items: center;

        & span {
          color: rgba(34, 34, 34, 1);
          font-size: 27px;
          font-weight: 400;
          line-height: 32.4px;
          letter-spacing: -2%;
          font-family: "Inter-Bold", sans-serif;
          align-items: center;
        }

        & div {
          width: 4px;
          height: 4px;
          background-color: #222;
          border-radius: 100%;
          margin: 0 5px;
        }

        & h3 {
          font-size: 27px;
          font-weight: 500;
          line-height: 32.4px;
          letter-spacing: -0.03em;
        }
      }
    }

    &__card-container {
      display: flex;
      flex-direction: row;
      align-items: start;
      margin-top: 50px;
      gap: 50px;
      width: 100%;
      flex-wrap: wrap;

      & article img {
        width: 340px;
        height: 260px;
      }
    }
  }

}

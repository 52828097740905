.modal-withdraw {
  font-family: "Inter-Regular", sans-serif;

  &__footer-btn {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 12px;
    background-color: rgba(34, 34, 34, 1);
    color: #fff;
    font-size: 14px;
    font-family: "Inter-Regular", sans-serif;
    font-weight: 600;

    &:hover {
      background-color: rgba(34, 34, 34, 0.85);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: rgba(34, 34, 34, 0.6);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: max-content; // хз зачем тут было статичное значение, но оно мешает при переводе и вообще лишнее на мой взгляд

    //@media (max-width: 641px) {
    //  height: 65vh;
    //}

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: 100%;

      & span {
        font-family: "Inter-Bold", sans-serif;
        font-size: 27px;
        font-weight: 700;
        line-height: 32.4px;
        letter-spacing: -0.02em;
        padding-bottom: 8px;
      }

      & div {
        display: flex;
        align-items: center;
        gap: 5px;
        & div {

          & img {
            aspect-ratio: 1;
            width: 12px;
          }
        }

        & p {
          font-family: "Inter-Medium", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
          color: rgba(136, 136, 136, 1);
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }

    &__info {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 30px;

      &__address-input {
        display: flex;
        flex-direction: column;

        & h4 {
          font-family: "Inter-Regular", sans-serif;
          color: rgba(65, 65, 65, 1);
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          margin-bottom: 10px;
        }

        & div {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        & input {
          width: 70%;
          height: 36px;
          background: linear-gradient(
            90deg, 
            #F1F1F1 0%, 
            #F1F1F1 80%, 
            rgba(200, 200, 200, 1) 100%
        );
          font-size: 16px;
          font-family: "Inter-Regular", sans-serif;
          padding: 0 12px;
          border: none;
          outline: none;
          border-radius: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          mask-image: linear-gradient(
            to right, 
            rgba(0, 0, 0, 1) 60%, 
            rgba(0, 0, 0, 0.6) 80%, 
            rgba(0, 0, 0, 0) 100%
          );
        }

        & button {
          display: flex;
          align-items: center;
          gap: 5px;
          background-color: rgba(241, 241, 241, 1);
          color: #222;
          height: 36px;
          padding: 0 12px;
          border-radius: 8px;
          font-family: "Inter-Regular", sans-serif;

          &:hover {
          background-color: rgba(0, 0, 0, 0.15);
          }

          & img {
            aspect-ratio: 1;
            width: 16px;
          }

          & span {
            font-family: "Inter-Regular", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
            letter-spacing: -0.02em;
          }
        }
      }
    }

    &__amount {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-top: 30px;

      &__input-wrapper {
        display: flex;
        width: 100%;
        color: rgba(34, 34, 34, 1);
        font-size: 27px;
        font-weight: 700;
        font-family: "Inter-Medium", sans-serif;

        @media (max-width: 641px){
          padding-left: 50px;
        }
      }

      &__input {
        background-color: transparent;
        border: none;
        outline: none;
        color: rgba(34, 34, 34, 1);
        font-size: 27px;
        font-weight: 700;
        font-family: "Inter-Medium", sans-serif;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }

      &__button-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;
        flex-wrap: wrap;
        margin-top: 5px;
        padding-bottom: 24px;
      }

      &__button {
        background-color: rgba(241, 241, 241, 1);
        height: 36px;
        border-radius: 8px;
        padding: 0 12px;
        color: #222;
        font-size: 14px;
        font-family: "Inter-Medium", sans-serif;
      }
    }
  }
}

input[type='text']:focus,
input[type='number']:focus,
textarea:focus {
  font-size: inherit;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: inherit;
  }
}

// MOBILE DRAWER STYLE

.mobile-modal-withdraw__content {
  padding-top: 26px;
  &__header {
    & > span {
      color: #000000;
      font-size: 21px;
      font-family: "Inter-Medium", sans-serif;
      font-weight: 600;
      line-height: 25.2px;
      letter-spacing: -0.02em;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 4px;
      padding-bottom: 24px;
      padding-top: 8px;
      & > p {
        font-size: 14px;
        font-family: "Inter-Medium", sans-serif;
        font-weight: 500;
        line-height: 19.6px;
        letter-spacing: -0.02em;
        color: #888888;
      }
    }
  }

  &__info {
    &__address-input {
      & > h4 {
        color: #A0A0A0;
        font-size: 14px;
        font-family: "Inter-Regular", sans-serif;
        font-weight: 500;
        line-height: 19.6px;
        letter-spacing: -0.02em;
        padding-bottom: 8px;
      }
      & > div {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 24px;
        & > input {
          width: 80%;
          height: 36px;
          background: linear-gradient(
            90deg, 
            #F1F1F1 0%, 
            #F1F1F1 80%, 
            rgba(200, 200, 200, 1) 100%
        );
          font-size: 16px;
          font-family: "Inter-Regular", sans-serif;
          padding: 0 12px;
          border: none;
          outline: none;
          border-radius: 8px;
          mask-image: linear-gradient(
            to right, 
            rgba(0, 0, 0, 1) 60%, 
            rgba(0, 0, 0, 0.6) 80%, 
            rgba(0, 0, 0, 0) 100%
        );
        }
        & > button {
          display: flex;
          align-items: center;
          gap: 5px;
          background-color: rgba(241, 241, 241, 1);
          color: #222;
          height: 36px;
          padding: 0 12px;
          border-radius: 8px;
          font-family: "Inter-Regular", sans-serif;

          &:hover {
          background-color: rgba(0, 0, 0, 0.15);
          }

          & img {
            aspect-ratio: 1;
            width: 16px;
          }

          & span {
            font-family: "Inter-Regular", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
  &__amount {
    &__input-wrapper {
      display: flex;
      width: 100%;
      color: rgba(34, 34, 34, 1);
      font-size: 27px;
      font-weight: 700;
      font-family: "Inter-Bold", sans-serif;
      padding: 24px 0;
    }
    &__input {
      background-color: transparent;
      border: none;
      outline: none;
      color: rgba(34, 34, 34, 1);
      font-size: 27px;
      font-weight: 700;
      font-family: "Inter-Bold", sans-serif;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    &__button-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 5px;
      flex-wrap: wrap;
      margin-top: 5px;
      padding-bottom: 24px;
      & > button {
        background-color: rgba(241, 241, 241, 1);
      height: 36px;
      border-radius: 8px;
      padding: 0 12px;
      color: #222222;
      font-size: 14px;
      font-family: "Inter-Medium", sans-serif;
      font-weight: 500;
      }
    }
  }
  .finances__deposit-network {
    padding-bottom: 24px;
    &-label {
      color: #A0A0A0;
      font-size: 14px;
      font-family: "Inter-Regular", sans-serif;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: -0.02em;
    }
  }
}
.modal-withdraw__footer-btn {
  font-family: "Inter-Regular", sans-serif;
  font-weight: 500;
}
.ant-drawer-footer {
  padding: 0 40px 24px 25px;
  border: none !important;
}
.mobile-modal-withdraw__footer-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 10px 145px;
  background-color: #222222;
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  font-family: "Inter-Medium", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 19.4px;
}
@media screen and (min-width: 786px) and (max-width: 896px) {
  .ant-drawer-footer {
    padding: 0;
    .mobile-modal-withdraw__footer-btn {
      padding: 6px 145px;
    }
  }
}
@media screen and (min-width: 390px) and (max-width: 440px) {
  .ant-drawer-footer {
    padding: 27px 16px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
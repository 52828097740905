.message-bubble {
  &__to-me {
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-family: Inter, sans-serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    line-height: 1.4;
    padding: 12px 16px;
    max-width: 70%;

    &__content {
      font-size: 14px;
      flex: 1;
      font-family: 'Inter-Medium', sans-serif;
      word-break: break-all;
    }

    &__time {
      font-size: 14px;
      flex: 1;
      font-family: 'Inter-Medium', sans-serif;
      color: rgba(160, 160, 160, 1);
    }
  }

  &__from-me {
    border-radius: 20px;
    background-color: rgba(88, 86, 214, 0.16);
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-family: Inter, sans-serif;
    color: #222;
    font-weight: 400;
    line-height: 1.4;
    padding: 12px 16px;
    max-width: 70%;
    margin-left: auto;

    &__content {
      font-size: 14px;
      flex: 1;
      font-family: 'Inter-Medium', sans-serif;
      word-break: break-all;
    }

    &__time {
      font-size: 14px;
      flex: 1;
      font-family: 'Inter-Medium', sans-serif;
      color: rgba(160, 160, 160, 1);
    }

    &__time-wrapper {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: end;
      align-items: center;

      & p {
        font-size: 14px;
        font-family: 'Inter-Medium', sans-serif;
        color: rgba(160, 160, 160, 1);
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      & div {
        width: 4px;
        height: 4px;
        background-color: #222;
        border-radius: 100%;
        margin: 0 5px;
        opacity: 0.45;
      }
    }
  }

  &__system {
    display: flex;
    max-width: 352px;
    flex-direction: column;
    font-family: Inter, sans-serif;
    text-align: center;
    justify-content: start;
    padding: 20px 0;
    margin-left: auto;
    margin-right: auto;

    &__timestamp {
      color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: -0.13px;
    }

    &__content {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.15px;
      margin-top: 20px;
    }

    &__highlight {
      color: #007aff;
    }
  }

  &__img {
    max-width: 100%;
    height: 150px;
    margin-bottom: 5px;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &__file{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 10px;

    &__downloadIcon{
      background: rgb(208, 208, 208);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      cursor: pointer;
    }

    &__fileName{
      font-family: 'Inter-Medium', sans-serif;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }
  }
}

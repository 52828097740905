@mixin flex-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
}

@mixin for-phone-only {
  @media (max-width: 991px) { @content; }
}

.orders {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  &__content {
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;

    &__list-second {
      margin-top: 50px;

      @include for-phone-only{
        margin-top: 10px;
      }
    }
  }

  // Header
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 32px 0 48px;
    border-bottom: 1px solid rgba(241, 241, 241, 1);

    @include for-phone-only {
      padding: 32px 24px 0;
    }

    &__title {
      display: flex;
      width: 100%;
      justify-content: space-between;

      & h1 {
        font-family: "Inter-Bold", sans-serif;
        font-size: 27px;
        font-weight: 500;
        line-height: 32.4px;
        letter-spacing: -0.02em;
        color: #222;
      }

      & button {
        display: flex;
        align-items: center;
        background-color: rgba(241, 241, 241, 1);
        height: 36px;
        border-radius: 8px;
        padding: 0 12px;
        gap: 6px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.15);
        }

        & span {
          font-family: "Inter-Medium", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          letter-spacing: -0.02em;
          color: rgba(34, 34, 34, 1);
        }

        & img {
          aspect-ratio: 1;
          width: 16px;
        }
      }
    }

    &__buttons {
      display: flex;
      width: 100%;
      gap: 7px;
      margin-top: 28px;

      @include for-phone-only {
        margin-top: 24px;
      }

      &__tab {
        &-container {
          border-bottom: 1px solid #f1f1f1;
          display: flex;
          width: 100%;
          gap: 29px;
          font-size: 14px;
          color: #a0a0a0;
          font-weight: 500;
          letter-spacing: -0.28px;
          line-height: 1.4;
          flex-wrap: wrap;
          padding: 0 64px;

          @media (max-width: 991px) {
            max-width: 100%;
            white-space: initial;
            padding: 0 20px;
          }
        }

        &--active {
          align-items: start;
          border-bottom: 1px solid #222;
          display: flex;
          min-height: 36px;
          color: #222;
          justify-content: start;
          padding: 8px 12px;

          @media (max-width: 991px) {
            white-space: initial;
          }
        }

        &--inactive {
          display: flex;
          min-height: 36px;
          align-items: start;
          justify-content: start;
          padding: 8px 12px;
          color: rgba(160, 160, 160, 1);

          & img {
            opacity: 0.65;
          }

          @media (max-width: 991px) {
            white-space: initial;
          }
        }

        &__button {
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: center;
          background: none;
          border: none;
          cursor: pointer;
          font-size: inherit;
          color: inherit;
          font-family: "Inter-Regular", sans-serif;

          @media (max-width: 991px) {
            white-space: initial;
          }
        }

        &__icon {
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center;
          width: 16px;
          align-self: stretch;
          margin: auto 0;
        }

        &__text {
          align-self: stretch;
          margin: auto 0;
          font-size: 14px;
          font-family: "Inter-Medium", sans-serif;
          font-weight: 500;
        }
      }
    }
  }

  // Search
  &__search {
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 26px;

    @include for-phone-only{
      margin: 24px 24px 17px;
    }

    &__wrap {
      width: 300px;
      position: relative;

      @include for-phone-only{
        width: auto;
      }

      &__input {
        background-color: rgba(248, 248, 248, 1);
        width: 100%;
        border-radius: 8px;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 9.5px 12px;
        font-family: 'Inter-Medium', sans-serif;

        &:focus {
          font-size: 14px !important;
        }

        &::placeholder{
          color: rgba(160, 160, 160, 1);
        }
      }

      &__icon {
        position: absolute;
        top: 9px;
        right: 15px;
      }
    }
  }

  // Header for list
  &__group-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    & span {
      color: rgba(34, 34, 34, 1);
      font-size: 21px;
      font-weight: 500;
      line-height: 32.4px;
      letter-spacing: -2%;
      font-family: "Inter-SemiBold", sans-serif;
      align-items: center;
    }

    & div {
      width: 4px;
      height: 4px;
      background-color: #222;
      border-radius: 100%;
      margin: 0 5px;
    }

    & h3 {
      font-size: 21px;
      font-weight: 500;
      line-height: 32.4px;
      letter-spacing: -0.03em;
      font-family: "Inter-Medium", sans-serif;
    }
  }

  // Active orders list
  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px 32px 0 48px;

    @include for-phone-only {
      padding: 20px 24px 0;
      gap: 13px;
    }

    &__custom_table {

    }

    &__person {
      display: flex;
      align-items: center;
      gap: 8px;

      & img {
        width: 16px;
        height: 16px;
        border-radius: 8px;
      }

      &__title {
        font-family: "Inter-Medium", sans-serif;
        font-size: 14px;
      }
    }

    &__title {
      font-size: 14px;
      font-family: "Inter-Regular", sans-serif;
      font-weight: 400;
    }

    &__package_name {
      display: flex;
      align-items: center;
      gap: 16px;

      & img {
        width: 48px;
        height: 36px;
        object-fit: cover;
      }
    }

    &__buttons {
      &__message {
        display: flex;
        align-items: center;
        height: 36px;
        background-color: rgba(241, 241, 241, 1);
        color: #222;
        border-radius: 8px;
        padding: 0 12px;
        gap: 4px;
        font-family: "Inter-Regular", sans-serif;

        &:hover {
          background-color: rgba(0, 0, 0, 0.15);
        }

        & span {
          font-family: "Inter-Medium", sans-serif;
          font-size: 14px;
          line-height: 19.6px;
          letter-spacing: -0.02em;
        }

        & img {
          aspect-ratio: 1;
          width: 16px;
        }
      }

      &__menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: rgba(241, 241, 241, 1);
        color: #222;
        border-radius: 8px;
        padding: 0 12px;
        font-family: "Inter-Regular", sans-serif;

        &:hover {
          background-color: rgba(0, 0, 0, 0.15);
        }

        & img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }
    }

    &__desktop-table {
      @media (max-width: 641px) {
        display: none;
      }

      &__header {
        display: flex;
        align-items: center;
        gap: 5px;

        &__title {
          font-size: 14px;
          font-weight: 500;
          color: #A0A0A0;
          font-family: 'Inter-Medium', sans-serif;
        }

        &-last {
          display: flex;
          align-items: center;
          gap: 5px;
          justify-content: flex-end;
        }
      }
    }

    &__status {
      display: flex;

      & span {
        padding: 2px 8px;
        border-radius: 100px;
        font-family: 'Inter-Medium', sans-serif;
        font-size: 14px;
      }
    }

    &__mobile-table {
      display: none;
      flex-direction: column;
      gap: 10px;

      @media (max-width: 641px) {
        display: flex;
      }

      &__item {
        display: flex;
        width: 100%;
        height: 64px;
        gap: 16px;
        border-bottom: 1px solid rgba(241, 241, 241, 1);
        padding: 10px 0;

        &__img img {
          width: 48px;
          height: 36px;
          border-radius: 3.6px 0 0 0;
        }

        &__info {
          display: flex;
          flex-direction: column;
          //justify-content: space-between;

          & span {
            font-family: "Inter-Regular", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
            color: rgba(34, 34, 34, 1);
          }

          & p {
            font-family: "Inter-Medium", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 19.6px;
            letter-spacing: -0.02em;
          }
        }

        &__menu {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;

          & button {
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
              aspect-ratio: 1;
              width: 16px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

}
